import {
	Button,
	cn,
	LoadingSpinner,
	NextStepIcon,
	Text,
} from 'crunch-components';
import { range } from 'crunch-utils';
import * as d3 from 'd3';
import * as React from 'react';
import tailwindColors from 'tailwindcss/colors';
import { ObjectiveScenario } from '../types/residualValueTypes';
import { GraphData } from '../types/strategies';
import { AdvancedSettings } from '../v2/AdvancedSettingsDialog';
import calculateAuc from './calculateAucValue';

type IntensityLevel = number;
/**
 * Between 0 and 100
 */
type Percentage = number;

type ResidualValuePresetSliderProps = {
	className?: string;
	options: ObjectiveScenario[];
	onChange: (e: any) => {};
} & (
	| {
			value: IntensityLevel;
	  }
	| {
			value: 'custom';
			maxResidualValue: Percentage;
			minResidualValue: Percentage;
			sellThroughTarget: Percentage;
	  }
) &
	(
		| {
				styling: 'new';
				onSaveCustom: () => void;
				valueCustom: GraphData;
				onChangeCustom: (val: GraphData) => void;
		  }
		| { styling?: never }
	);

type SliderBarProps = {
	/** Css value like rgb(...), #ff00ff etc */
	backgroundColorCss: string;
	/** Css value like rgb(...), #ff00ff etc */
	foregroundColorCss: string;
	/** Between 0 and 1 */
	progress: number;
	className?: string;
};

const SliderBar: React.FC<SliderBarProps> = (props) => {
	return (
		<div
			className={cn(props.className ?? '', 'relative rounded-full h-1 w-full')}
			style={{ backgroundColor: props.backgroundColorCss }}
		>
			<div
				className="absolute rounded-full h-1 left-0 top-0 bottom-0 transition-all"
				style={{
					backgroundColor: props.foregroundColorCss,
					right: `${100 - props.progress * 100}%`,
				}}
			/>
		</div>
	);
};

type SliderLabelProps = {
	/** Between 0 and 1 */
	progress: number;
	text: string;
};

const SliderLabel: React.FC<SliderLabelProps> = (props) => {
	return (
		<div
			className="absolute -translate-x-1/2 z-0 transition-all pointer-events-none select-none"
			style={{ left: `${props.progress * 100}%` }}
		>
			<Text className="absolute top-3 left-1/2 -translate-x-1/2 text-zinc-800 px-2 pb-0.5 rounded-md transition-all">
				{props.text}
			</Text>
		</div>
	);
};

const NOTCH_SIZES = {
	sm: {
		tailwindClass: 'w-2 h-2 border',
		px: 8,
	},
	lg: {
		tailwindClass: 'w-4 h-4 border-2',
		px: 16,
	},
} as const;

type SliderNotchProps = {
	/** Between 0 and 1 */
	progress: number;
	size: keyof typeof NOTCH_SIZES;
	colorCss?: string;
	borderColorCss?: string;
};

const SliderNotch: React.FC<SliderNotchProps> = (props) => {
	return (
		<div
			className={cn(
				NOTCH_SIZES[props.size].tailwindClass,
				'absolute rounded-full transition-all',
			)}
			style={{
				borderColor: props.borderColorCss,
				backgroundColor: props.colorCss,
				left: `${props.progress * 100}%`,
				transform: `translate(${
					-props.progress * NOTCH_SIZES[props.size].px
				}px, -50%)`,
			}}
		/>
	);
};

type SliderNotchesProps = {
	step: number;
	min: number;
	max: number;
	value: number;
	activeFillColorCss: string;
	activeBorderColorCss: string;
	inactiveFillColorCss: string;
	inactiveBorderColorCss: string;
};

const SliderNotches: React.FC<SliderNotchesProps> = (props) => {
	return (
		<div className="row-start-1 row-span-1 col-start-1 col-span-1 relative">
			{range(props.min, props.max, props.step).map((t) => (
				<SliderNotch
					progress={t}
					size={Math.abs(t - props.value) <= Number.EPSILON ? 'lg' : 'sm'}
					colorCss={
						t - props.value < -Number.EPSILON
							? props.activeFillColorCss
							: props.inactiveFillColorCss
					}
					borderColorCss={
						t - props.value <= Number.EPSILON
							? props.activeBorderColorCss
							: props.inactiveBorderColorCss
					}
					key={`notch-${t}`}
				/>
			))}
		</div>
	);
};

const ResidualValuePresetSlider: React.FC<ResidualValuePresetSliderProps> = (
	props,
) => {
	if (props?.options?.length === 0 || props.value === undefined) {
		return (
			<div>
				Loading...
				<LoadingSpinner variant="lg" />
			</div>
		);
	}

	const { styling } = props;
	const hasNewStyling = styling === 'new';

	const colorScale = d3.scaleLinear(
		[0, 0.3, 0.75, 1],
		[
			tailwindColors.emerald['400'],
			tailwindColors.green['500'],
			tailwindColors.yellow['500'],
			tailwindColors.red['500'],
		],
	);

	const orderedDefaultScenarios = (props.options ?? []).sort(
		(a, b) => a.intensity_level - b.intensity_level,
	);

	const step = props.options?.length < 2 ? 1 : 1 / (props.options?.length - 1);
	let activeName = '';
	let labelPosition = NaN;
	let activeHexColor = '';
	let sliderValue = NaN;
	let valueIndex = NaN;
	let foundIndex = NaN;
	const aucThresholds = orderedDefaultScenarios.map((scenario) => scenario.auc); // get auc values from default scenarios

	if (props.value === 'custom') {
		// we need to calculate the AUC, and then find the intensity_level of the default scenario with AUC closest to the current AUC

		const aucCurrent = calculateAuc(
			props.minResidualValue,
			props.maxResidualValue,
			props.sellThroughTarget,
		);

		const aucCurrentThreshold = aucThresholds.reduce((prev, curr) => {
			return Math.abs(curr - aucCurrent) < Math.abs(prev - aucCurrent)
				? curr
				: prev;
		});
		foundIndex =
			orderedDefaultScenarios.findIndex(
				(scenario) => scenario.auc === aucCurrentThreshold,
			) ?? 1;
		sliderValue =
			orderedDefaultScenarios.length === 1 ? step : foundIndex * step;
		valueIndex = orderedDefaultScenarios.findIndex(
			(scenario) => scenario.auc === aucCurrentThreshold,
		);
		activeName = `${props.value}`; // e.g.: 'custom - aggressive'
		labelPosition = sliderValue; // mapped to closest intensity
		activeHexColor = d3.color(colorScale(sliderValue))?.formatHex() ?? ''; // color based on sliderValue
	} else if (props.value !== undefined) {
		foundIndex =
			orderedDefaultScenarios.findIndex(
				(scenario) => scenario.intensity_level === props.value,
			) ?? 1;
		sliderValue =
			orderedDefaultScenarios.length === 1 ? step : foundIndex * step;

		valueIndex = orderedDefaultScenarios.findIndex(
			(scenario) => scenario.intensity_level === props.value,
		);
		activeName = orderedDefaultScenarios[valueIndex].name;
		labelPosition = sliderValue;
		activeHexColor = d3.color(colorScale(sliderValue))?.formatHex() ?? '';
	}

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		const scenarioIndex =
			orderedDefaultScenarios.length === 1
				? 0
				: Math.round((parseFloat(value) * 1) / step);
		props.onChange(orderedDefaultScenarios[scenarioIndex].intensity_level);
	};

	return (
		<div className={cn(props.className, 'flex gap-4')}>
			{hasNewStyling ? null : (
				<Text size="text-sm" type="secondary" key="slider-low">
					Low
				</Text>
			)}
			<div
				className={cn(
					'relative grid grid-rows-1 grid-cols-1 items-center flex-grow',
					hasNewStyling && 'grid-cols-[auto_140px]',
				)}
			>
				<SliderBar
					className="row-start-1 col-start-1"
					progress={sliderValue}
					backgroundColorCss={tailwindColors.zinc['200']}
					foregroundColorCss={activeHexColor}
				/>
				{hasNewStyling ? (
					<AdvancedSettings
						onSave={props.onSaveCustom}
						value={props.valueCustom}
						onChange={props.onChangeCustom}
					>
						<Button
							className="text-left ml-4 flex flex-col items-start justify-center hover:bg-ca-gray-200 rounded text-ca-gray-500 group p-2 h-[50px]"
							variant="unstyled"
						>
							<span className="flex flex-row items-center">
								<NextStepIcon
									className="mr-2 h-1.5 w-auto"
									style={{ color: activeHexColor }}
								/>
								<span className="capitalize underline group-hover:no-underline text-ca-gray-500 text-xs">
									{activeName}
								</span>
							</span>
							<span className="hidden group-hover:inline-flex text-xs">
								advanced settings
							</span>
						</Button>
					</AdvancedSettings>
				) : (
					<SliderLabel progress={labelPosition} text={activeName} />
				)}
				<SliderNotches
					min={0}
					max={1}
					step={step}
					value={sliderValue}
					activeFillColorCss={activeHexColor}
					activeBorderColorCss={activeHexColor}
					inactiveFillColorCss={tailwindColors.zinc['200']}
					inactiveBorderColorCss={tailwindColors.zinc['300']}
				/>
				<input
					type="range"
					min={0}
					max={1}
					step={step}
					value={sliderValue.toString()}
					className="row-start-1 col-start-1 z-10 opacity-0 w-full"
					onChange={handleOnChange}
				/>
			</div>
			{hasNewStyling ? null : (
				<Text size="text-sm" type="secondary" key="slider-high">
					High
				</Text>
			)}
		</div>
	);
};

export default ResidualValuePresetSlider;
