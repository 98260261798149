import { Dropdown } from 'crunch-components';
import useChannelStore from 'hooks/channels/useChannelStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { isFeatureEnabled } from 'utils/featureUtils';
import { DEFAULT_PATHS } from 'utils/routerUtils';

const ChannelSelector = () => {
	const { channels, activeChannel, setActiveChannel } = useChannelStore();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	if (channels.length <= 1) {
		// if there is only 1 channel, we do not need a selector
		return null;
	}

	const options = channels.map((name) => ({
		value: name,
		label: name,
	}));

	if (isFeatureEnabled('global')) {
		options.push({
			value: 'global',
			label: 'Global',
		});
	}

	const handleChannelChange = (channel) => {
		if (channel === 'global') {
			navigate('/global');
		} else {
			setActiveChannel(channel);

			// navigate away so we don't get stuck in global mode
			navigate(DEFAULT_PATHS['private'] || pathname);
		}
	};

	const isGlobalMode = pathname.startsWith('/global');

	return (
		<Dropdown
			onChange={handleChannelChange}
			value={isGlobalMode ? 'global' : activeChannel}
			options={options}
			className="w-64"
		/>
	);
};

export default ChannelSelector;
