/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { LIST_PHASES } from 'api/seasons';
import {
	GET_NEW_STRATEGY_DEFAULT,
	LIST_DEFAULT_SCENARIOS,
	LIST_STRATEGIES,
} from 'api/strategies-v2';

import { LinearProgress } from 'crunch-components';
import useChannelQueries from 'hooks/channels/useChannelQueries';
import { useLoaderData } from 'react-router-dom';
import { PhasesDataType } from '../types/seasons';
import { ScenariosDataType, StrategiesDataType } from '../types/strategies';
import {
	DEFAULT_SCENARIOS_QUERY_KEY,
	NEW_STRATEGY_DEFAULT_QUERY_KEY,
	SEASON_PHASES_QUERY_KEY,
	STRATEGY_QUERY_KEY,
	transformPhases,
} from './queries';
import { StrategiesTable } from './StrategiesTable';
/**
 * Root component for this url
 * url: strategy/strategies
 */
export const StrategiesPage = () => {
	const data = useLoaderData();
	const [strategiesResp, scenariosResp, phasesResp, defaultScenarioResp] =
		useChannelQueries({
			queries: [
				{
					queryKey: STRATEGY_QUERY_KEY,
					queryFn: LIST_STRATEGIES,
				},
				{
					queryKey: DEFAULT_SCENARIOS_QUERY_KEY,
					queryFn: LIST_DEFAULT_SCENARIOS,
				},
				{
					queryKey: SEASON_PHASES_QUERY_KEY,
					queryFn: LIST_PHASES,
					select: transformPhases,
				},
				{
					queryKey: NEW_STRATEGY_DEFAULT_QUERY_KEY,
					queryFn: GET_NEW_STRATEGY_DEFAULT,
				},
			],
		});

	const {
		isLoading: isStrategiesLoading,
		data: strategiesData,
		refetch: refetchStrategies,
	} = strategiesResp;

	const {
		isLoading: isScenariosLoading,
		data: scenariosData,
		refetch: refetchScenarios,
	} = scenariosResp;

	const {
		isLoading: isPhasesLoading,
		data: phasesData,
		refetch: refetchPhases,
	} = phasesResp;

	const {
		isLoading: isDefaultScenarioLoading,
		data: defaultScenarioData,
		refetch: refetchDefaultScenario,
	} = defaultScenarioResp;

	const strategies = (strategiesData as StrategiesDataType) ?? [];
	const defaultScenarios = (scenariosData as ScenariosDataType) ?? [];
	const newStrategyDefault =
		(defaultScenarioData as ScenariosDataType[number]) ?? {};
	// @ts-ignore
	const phases = (phasesData?.afterNow as PhasesDataType) ?? [];
	const isLoading = isStrategiesLoading || isScenariosLoading;

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress visible={isLoading} />
			</div>
			<StrategiesTable
				strategies={strategies}
				isLoading={isLoading}
				phases={phases}
				defaultScenarios={defaultScenarios}
				newStrategyDefault={newStrategyDefault}
				refetchStrategies={refetchStrategies}
			/>
		</>
	);
};
