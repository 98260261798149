import FullFilterMenu from 'components/Filter/FullFilterMenu';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import useStrategyStore from 'hooks/useStrategyStore';
import { components } from '../../../../types/backend-api';
import { GET_RECOMMENDATIONS_FILTERS } from '../../../api/recommendations';
import {
	AllStrategiesStatusDataType as Statuses,
	Strategy,
} from '../../strategy/types/strategies';

type RecommendationsUIFilter =
	components['schemas']['RecommendationUIFilterDefinition'];

type RecommendationsFilterProps = {
	disableStrategySpecific?: boolean;
	strategyStatuses?: Statuses;
};

/**
 * Selects the strategy to be used as the input for the filters query.
 *
 * Will return the active strategy if useLastRun is `false`.
 * If useLastRun is true the function will attempt to determine which strategy was last successfully
 * run, falling back to the activeStrategy if needed.
 */
function getFilterStrategy(
	useLastRun: boolean,
	activeStrategyId: any,
	strategies?: Strategy[],
	strategyStatuses?: Statuses,
): string {
	if (
		!useLastRun ||
		strategyStatuses === undefined ||
		strategies == null ||
		strategies.length === 0
	) {
		return activeStrategyId;
	}

	const lastSuccessfulStatus = Object.values(strategyStatuses)
		.filter((s) => s?.simplified_status === 'DONE')
		.sort(
			(a, b) => (a!.updated_at < b!.updated_at! ? 1 : -1), // DESC
		)[0];

	if (!lastSuccessfulStatus) {
		return activeStrategyId;
	}

	const lastSuccessFulStrategy = strategies.find(
		(s) => s.id === lastSuccessfulStatus.strategy_id,
	);

	if (!lastSuccessFulStrategy) {
		throw new Error(
			`[getFilterStrategy] status.strategyId not found in strategies. (impossible)
			${{ lastSuccessFulStrategy, strategies }}`,
		);
	}
	return lastSuccessFulStrategy.id;
}
const RecommendationsFilter = (props: RecommendationsFilterProps) => {
	const { activeStrategy, filters, setFilters, strategies } =
		useStrategyStore();
	const filterStrategy = getFilterStrategy(
		props.disableStrategySpecific ?? false,
		activeStrategy,
		strategies,
		props.strategyStatuses,
	);
	const filterQuery = () =>
		useChannelQuery<
			RecommendationsUIFilter[],
			unknown,
			RecommendationsUIFilter[]
		>(
			['recommendations-filters', filterStrategy],
			() => GET_RECOMMENDATIONS_FILTERS(filterStrategy),
			{ staleTime: 5 * 60 * 1000 },
		);

	const disableFilter = props.disableStrategySpecific
		? (f: RecommendationsUIFilter) => f.strategy_specific === true
		: () => false;
	const disableFilterText = props.disableStrategySpecific
		? 'This filter cannot be applied on the strategy comparison dashboard.'
		: undefined;

	return (
		<FullFilterMenu
			filterQuery={filterQuery}
			filters={filters}
			disableFilter={disableFilter}
			disableFilterText={disableFilterText}
			setFilters={setFilters}
		/>
	);
};

export default RecommendationsFilter;
