import { jsx as _jsx } from "react/jsx-runtime";
import { DayPicker } from 'react-day-picker';
import cn from '../../utils/cn';
import Caret from '../Icons/Caret';
function Calendar({ className, classNames, ...props }) {
    return (_jsx(DayPicker, { className: cn('p-3', className), classNames: {
            months: 'flex flex-col sm:flex-row gap-8 ',
            month: 'space-y-4',
            caption: 'flex justify-center pt-1 relative items-center text-ca-black ',
            caption_label: 'text-sm font-medium',
            nav: 'space-x-1 flex items-center',
            nav_button: cn('h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 bg-transparent hover:bg-gray-100 rounded-full flex justify-center items-center transition-all'),
            nav_button_previous: 'absolute left-1',
            nav_button_next: 'absolute right-1',
            table: 'w-full border-collapse space-y-1',
            head_row: 'flex [&>*:first-child]:mr-4',
            head_cell: 'text-slate-500 rounded-md w-9 font-normal text-[0.8rem]',
            row: cn('flex w-full mt-2 [&>*:nth-child(2)]:rounded-l-lg [&>*:last-child]:rounded-r-lg [&>*:last-child]:overflow-hidden [&>*:nth-child(2)]:overflow-hidden [&>*:first-child]:mr-4'),
            cell: 'h-9 w-9 text-center text-sm p-0',
            day: cn('h-9 w-9 font-normal text-ca-black '),
            // cell: '[&:has([aria-selected].day-outside)]:bg-slate-100/50' // interesting to know: you can select the selected day while it is an 'outside this month day' using this selector
            // day_range_end: 'day-range-end',
            // day_selected:
            // 	'bg-slate-900 text-slate-50 hover:bg-slate-900 hover:text-slate-50 focus:bg-slate-900 focus:text-slate-50 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50 dark:hover:text-slate-900 dark:focus:bg-slate-50 dark:focus:text-slate-900',
            day_today: 'border radius-sm border-red-400 bg-red-400 outline font-bold',
            day_outside: 'day-outside text-slate-500 opacity-20  italic',
            day_disabled: "relative text-gray-500 after:content-['_'] after:absolute after:top-1/2 after:bottom-0 after:left-0 after:right-0 after:h-0 after:border-b after:border-b-gray-400",
            // day_range_middle:
            // 	'aria-selected:bg-slate-100 aria-selected:text-slate-900 dark:aria-selected:bg-slate-800 dark:aria-selected:text-slate-50',
            day_hidden: 'invisible',
            ...classNames,
        }, components: {
            WeekNumber: (p) => {
                return (_jsx("div", { className: "bg-gray-100 rounded-full text-xs h-full flex items-center justify-center text-gray-600 scale-75", children: p.number }));
            },
            IconLeft: ({ className: csn, ...params }) => {
                return (_jsx(Caret, { orientation: "left", className: cn(csn, 'h-4 w-4'), ...params }));
            },
            IconRight: ({ className: csn, ...params }) => {
                return (_jsx(Caret, { orientation: "right", className: cn(csn, 'h-4 w-4'), ...params }));
            },
        }, ...props }));
}
Calendar.displayName = 'Calendar';
export default Calendar;
