/**
 * returns an object with selected keys (needed for API calls)
 */
export function pick(obj, keys) {
    const result = {};
    keys.forEach((key) => {
        if (key in obj) {
            result[key] = obj[key];
        }
    });
    return result;
}
/**
 * makes TS happy by validating object key
 */
export function isValidKey(key, obj) {
    return key in obj;
}
