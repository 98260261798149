import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Warning = ({ className }) => {
    return (_jsxs("svg", { className: className, xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 15 14", children: [_jsxs("g", { fill: "currentColor", clipPath: "url(#clip0_2011_1198)", children: [_jsx("path", { d: "M7.5 0a7 7 0 107 7 7.008 7.008 0 00-7-7zm0 12.923A5.923 5.923 0 1113.423 7 5.93 5.93 0 017.5 12.923zm-.538-5.385V3.77a.538.538 0 111.076 0v3.77a.538.538 0 11-1.076 0zm1.346 2.424a.808.808 0 11-1.616 0 .808.808 0 011.616 0z" }), _jsx("path", { d: "M6.722 3.89v3.37a.778.778 0 001.556 0V3.89a.778.778 0 00-1.556 0zM8.362 10.428a1.037 1.037 0 10-1.724-1.152 1.037 1.037 0 001.724 1.152z" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_2011_1198", children: _jsx("path", { fill: "currentColor", d: "M0 0H14V14H0z", transform: "translate(.5)" }) }) })] }));
};
Warning.propTypes = {
    className: PropTypes.string,
};
Warning.defaultProps = {
    className: '',
};
export default Warning;
