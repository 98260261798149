import { useAuth0 } from '@auth0/auth0-react';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { CircularProgress, Modal, Text, useModal } from 'crunch-components';

import { GET_CHANNELS } from '../api/channels';
import useChannelStore from '../hooks/channels/useChannelStore';

const ChannelContext = createContext(null);

const ChannelProvider = (props: PropsWithChildren<{}>): JSX.Element => {
	const [counter, setCounter] = useState(0);
	const { setChannels, activeChannel, setActiveChannel } = useChannelStore();
	const [prevActiveChannel, setPrevActiveChannel] = useState(activeChannel);
	const { open, close: rawClose } = useModal();
	const close = () => {
		if (rawClose !== undefined) {
			rawClose();
		}
	};

	const { isAuthenticated } = useAuth0();

	const { isFetching } = useQuery('channels', GET_CHANNELS, {
		staleTime: 15 * 60 * 1000,
		enabled: isAuthenticated,
		onSuccess: (data) => {
			if (data.length) {
				console.log({ channels: data.map(({ name }) => name) });
				setChannels(data.map(({ name }) => name));

				// check if the active channel from cache exists
				const active = data?.find(({ name }) => name === activeChannel);

				// if no active channel was set, default set it to the first channel in the list
				if (!active) {
					const channel = data?.[0]?.name;
					setActiveChannel(channel || '');
				}
			}
		},
	});

	useEffect(() => {
		// active channel changed
		if (activeChannel && activeChannel !== prevActiveChannel) {
			setPrevActiveChannel(activeChannel);
			open({
				modalNode: (
					<Modal.Root>
						<Modal.Content>
							<Modal.Title>
								<span className="block text-center">Switching channel</span>
							</Modal.Title>
							<div className="flex flex-wrap justify-center text-center space-y-8">
								<CircularProgress
									onAnimationEnded={() => setCounter((i) => i + 1)}
								/>
								<Text className="w-full">
									Just a moment please. This won&apos;t take long.
								</Text>
							</div>
						</Modal.Content>
					</Modal.Root>
				),
			});
		}
	}, [activeChannel, prevActiveChannel]);

	useEffect(() => {
		// close the loading modal after 2 iterations of the spinner
		if (counter >= 2) {
			close();
			setCounter(0);
		}
	}, [counter]);

	if (isFetching && !activeChannel) {
		return (
			<div className="min-h-screen flex justify-center items-center">
				<CircularProgress />
			</div>
		);
	}

	return <>{props.children}</>;
};

export { ChannelContext, ChannelProvider };
