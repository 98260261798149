import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from 'App';
import './index.css';

/* TODO ward: useRefreshTokens gives error when logging out 'cannot find refresh token'. Fix this together with react 19 auth fix*/

const $root = document.getElementById('root');
const root = createRoot($root!);
root.render(
	<StrictMode>
		<App />
	</StrictMode>,
);
