import useStrategyStore from 'hooks/useStrategyStore';
import { Outlet } from 'react-router-dom';

/**
 * Ensures active strategy is selected
 * Renders child routes if ActiveStrategy is valid
 * Renders warning page if ActiveStrategy undefined
 */
function NoActiveStrategyView() {
	return (
		<div className="flex items-center justify-center h-72">
			<span className="text-ca-gray">Please create a strategy first</span>
		</div>
	);
}

export default function EnsureActiveStrategy() {
	const { activeStrategy } = useStrategyStore();
	/* Note activeStrategy can be null in 2 situations
                1. No strategies created yet
                2. Strategies is being fetched 
    */
	return activeStrategy === null ? <NoActiveStrategyView /> : <Outlet />;
}
