import { ReactQueryDevtools } from 'react-query/devtools';

export default function DevelopmentWrapper(props: {
	children: React.ReactNode;
}) {
	if (process.env.NODE_ENV !== 'development') {
		return <>{props.children}</>;
	}

	return (
		<>
			<ReactQueryDevtools position="bottom-right" />
			<div className="text-red-600">{props.children}</div>
		</>
	);
}
