import { Route } from 'react-router-dom';
import ConditionalFeatureRoute from 'routing/components/ConditionalFeatureRoute';
import UploadsView from './views/UploadsView';

const uploadsRouting = (
	<Route
		path="uploads"
		element={
			<ConditionalFeatureRoute feature="uploader" renderMethod="children">
				<UploadsView />
			</ConditionalFeatureRoute>
		}
	/>
);

export default uploadsRouting;
