/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { LIST_OBJECTIVES } from 'api/objectives-v2';
import { LIST_DEFAULT_SCENARIOS } from 'api/strategies-v2';
import { LinearProgress } from 'crunch-components';
import useChannelQueries from 'hooks/channels/useChannelQueries';
import { ObjectivesDataType } from '../types/objectives';
import { ScenariosDataType } from '../types/strategies';
import { ObjectivesTable } from './ObjectivesTable';
import { DEFAULT_SCENARIOS_QUERY_KEY, OBJECTIVE_QUERY_KEY } from './queries';

/**
 * Root component for this url
 * url: strategy/strategies
 */
export const ObjectivesPage = () => {
	const [objectivesResp, defaultScenariosResp] = useChannelQueries({
		queries: [
			{
				queryKey: OBJECTIVE_QUERY_KEY,
				queryFn: LIST_OBJECTIVES,
			},
			{
				queryKey: DEFAULT_SCENARIOS_QUERY_KEY,
				queryFn: LIST_DEFAULT_SCENARIOS,
			},
		],
	});

	const {
		isLoading: isObjectivesLoading,
		isFetching: isObjectivesFetching,
		data: objectivesData,
		refetch: refetchObjectives,
	} = objectivesResp;

	const {
		isLoading: isDefaultScenariosLoading,
		isFetching: isDefaultScenariosFetching,
		data: defaultScenariosData,
		refetch: _refetchDefaultScenarios,
	} = defaultScenariosResp;

	const objectives = ((objectivesData as ObjectivesDataType) ?? []).map(
		// Backend uses 0-based-counting for priority, UI shows 1-based
		(o) => ({ ...o, priority: o.priority + 1 }),
	);
	const defaultScenarios = (defaultScenariosData as ScenariosDataType) ?? [];
	const isLoading = isObjectivesLoading || isDefaultScenariosLoading;
	const isFetching = isObjectivesFetching || isDefaultScenariosFetching;

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress visible={isFetching} />
			</div>
			<ObjectivesTable
				objectives={objectives}
				defaultScenarios={defaultScenarios}
				refetchObjectives={refetchObjectives}
				isLoading={isLoading}
			/>
		</>
	);
};
