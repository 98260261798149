import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from '../../utils/cn';
import PropTypes from 'prop-types';
const RadioButton = ({ checked, label, disabled, className, onChange }) => {
    return (_jsxs("button", { disabled: disabled, type: "button", tabIndex: "-1", onClick: () => !disabled && onChange(!checked), className: cn('flex items-center transition-colors leading-none', disabled && 'cursor-not-allowed', className), children: [_jsx("span", { tabIndex: disabled ? '-1' : '0', role: "button", className: cn('inline-flex justify-center items-center w-4 h-4 bg-white rounded-full border-2 border-ca-silver focus:outline-none', disabled && 'pointer-events-none', !disabled &&
                    'focus:border-ca-purple focus:ring-4 focus:ring-opacity-10 focus:ring-ca-purple'), style: { minHeight: '16px', minWidth: '16px' }, children: checked && (_jsx("span", { className: cn('w-2 h-2 rounded-full', disabled ? 'bg-ca-gray' : 'bg-ca-purple') })) }), label && (_jsx("span", { className: cn('text-left ml-2', checked ? 'text-ca-black' : 'text-ca-gray'), children: label }))] }));
};
RadioButton.propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
};
RadioButton.defaultProps = {
    checked: false,
    label: '',
    disabled: false,
    className: 'text-sm',
    onChange: () => { },
};
export default RadioButton;
