import {
	ActualsIcon,
	BarChartIcon,
	cn,
	HelpIcon,
	Logo,
	LogOutIcon,
	StrategyIcon,
	Tooltip,
	UploadIcon,
	useJWTStore,
} from 'crunch-components';
import { NavLink, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import useChannelStore from 'hooks/channels/useChannelStore';
import { ElementType } from 'react';
import { isFeatureEnabled } from 'utils/featureUtils';
import { DEFAULT_PATHS } from 'utils/routerUtils';

type SidebarNavLinkProps = {
	icon: ElementType;
	label: string;
	to: string;
	disabled?: boolean;
	tooltip?: string;
};

/*  Note 07 november 2024 ward:
	 <SidebarNavLink> & <SidebarCircleLink> not in crunch-components 
	 because charlie rebrand is coming and 
	 I think that will require these components to be unique per app, 
	 so I am not putting in the work do share them now. 
*/
function SidebarNavLink(props: SidebarNavLinkProps) {
	const Icon = props.icon;
	return (
		<Tooltip content={props.tooltip} placement="right">
			<NavLink
				to={props.to}
				className={({ isActive, isPending }) =>
					cn(
						'group/li',
						isActive ? 'active' : 'not-active',
						isPending ? 'pending' : 'not-pending',
						(props.disabled ?? false)
							? 'opacity-50 cursor-not-allowed'
							: 'hover:text-ca-purple cursor-pointer',
					)
				}
			>
				<li className="relative flex flex-col flex-wrap items-center py-5 text-ca-gray cursor-pointer hover:border-ca-purple hover:text-ca-purple transition-all before:transition-all before:absolute before:left-0 before:top-0 before:h-full before:bg-ca-purple group-[.active]/li:before:w-1 group-[.not-active]/li:before:w-0">
					<Icon
						className={cn(
							'w-5 transition-colors',
							'group-[.active]/li:text-ca-purple',
						)}
					/>
					<span className="mt-4 max-w-4/5 text-center text-xs uppercase font-semibold tracking-tighter transition-all group-hover:h-4 group-[.active]/li:text-ca-purple group-hover/ul:h-4 group-[.active]/li:h-4 group-[.active]/li:opacity-100 h-0 opacity-0 group-hover/ul:opacity-100">
						{props.label}
					</span>
				</li>
			</NavLink>
		</Tooltip>
	);
}

function SidebarCircleLink(props: {
	icon: ElementType;
	tooltip: string;
	to?: string;
	onClick?: () => void;
}) {
	const navigate = useNavigate();

	function handleOnClick() {
		if (props.to !== undefined) {
			navigate(props.to);
		} else if (props.onClick !== undefined) {
			props.onClick();
		}
	}
	const Icon = props.icon;
	return (
		<Tooltip content={props.tooltip}>
			<button
				className="p-4 border-2 border-ca-silver rounded-full hover:border-ca-purple focus:border-ca-purple group transition-colors mb-2"
				type="button"
				onClick={handleOnClick}
			>
				<Icon className="w-4 text-ca-gray group-hover:text-ca-purple transition-colors" />
			</button>
		</Tooltip>
	);
}

function LogoNav() {
	const navigate = useNavigate();

	return (
		<div
			role="button"
			tabIndex={0}
			className="relative cursor-pointer"
			onClick={() => navigate(DEFAULT_PATHS['private'])}
			onKeyDown={() => navigate(DEFAULT_PATHS['private'])}
		>
			<Logo className="w-12 h-full" />
		</div>
	);
}

export default function StratosSidebar() {
	const { logout: auth0Logout } = useAuth0();
	const { setJWT } = useJWTStore();
	const navigate = useNavigate();
	const { activeChannel } = useChannelStore();

	const logout = async () => {
		setJWT(null);
		await auth0Logout({
			logoutParams: {
				returnTo: window.location.origin, //todo ward: what does this do?this is now localhost:3000
			},
		});
		navigate(DEFAULT_PATHS['public']);
	};

	const openDocPage = () => {
		window.open('http://knowledge.crunchplatform.eu');
	};

	return (
		<div className="w-40 shrink-0 h-screen flex flex-col justify-between bg-white border-r border-ca-black border-opacity-5 z-10 sticky top-0">
			<div>
				<div className="flex justify-center py-10">
					<LogoNav />
				</div>
				<ul className="flex flex-col py-10 group/ul">
					{isFeatureEnabled('actuals', activeChannel) && (
						<SidebarNavLink icon={ActualsIcon} label="Actuals" to="/actuals" />
					)}
					{isFeatureEnabled('strategy', activeChannel) && (
						<SidebarNavLink
							icon={StrategyIcon}
							label="Strategy"
							to="/strategy"
						/>
					)}
					{isFeatureEnabled('recommendations', activeChannel) && (
						<SidebarNavLink
							icon={BarChartIcon}
							label="Recommendation"
							to="/recommendations"
						/>
					)}

					{isFeatureEnabled('global', activeChannel) && (
						<SidebarNavLink icon={ActualsIcon} label="Global" to="/global" />
					)}
				</ul>
			</div>
			<div className="flex justify-center items-center p-10 flex-col">
				{isFeatureEnabled('uploader', activeChannel) && (
					<SidebarCircleLink
						tooltip="Upload data"
						icon={UploadIcon}
						to="uploads"
					/>
				)}
				<SidebarCircleLink
					tooltip="Link to Knowledge Base"
					icon={HelpIcon}
					onClick={openDocPage}
				/>
				<SidebarCircleLink
					tooltip="Log out"
					icon={LogOutIcon}
					onClick={logout}
				/>
			</div>
		</div>
	);
}
