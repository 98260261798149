import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
/**
 * Evaluate access critiria
 * Render children if user allowed
 * otherwise redirect to the appropriate page (handle by this component)
 */
export default function AuthGuard(props) {
    const { isAuthenticated, isLoading } = useAuth0();
    const location = useLocation();
    // If authentication status is loading, show a loading indicator
    const shouldRedirect = (() => {
        switch (props.accessibleBy) {
            case 'private-only':
                return !isAuthenticated && !isLoading;
            case 'public-only':
                return isAuthenticated && !isLoading;
            default:
                return false;
        }
    })();
    const home = props.accessibleBy === 'private-only'
        ? props.publicHomePath
        : props.privateHomePath;
    if (shouldRedirect) {
        return _jsx(Navigate, { to: home, state: { from: location }, replace: true });
    }
    return _jsx(Outlet, {});
}
