import { useQueries } from 'react-query';
import constructChannelQueryKey from '../../utils/channelUtils';
import useChannelStore from './useChannelStore';

// wrapper for useQuery that ensures caching on channel level
const useChannelQueries = (queries) => {
	const activeChannel = useChannelStore((s) => s.activeChannel);

	const channelQueries =
		queries?.queries?.map((query) => {
			const queryKeyWithActiveChannel = constructChannelQueryKey(
				activeChannel,
				query.queryKey,
			);
			return { ...query, queryKey: queryKeyWithActiveChannel };
		}) || [];

	return useQueries(channelQueries);
};

export default useChannelQueries;
