import { ChannelProvider } from 'contexts/ChannelProvider';
import { StrategyProvider } from 'contexts/StrategyProvider';
import { Outlet } from 'react-router-dom';

export default function PrivateProviders() {
	return (
		<ChannelProvider>
			<StrategyProvider>
				<Outlet />
			</StrategyProvider>
		</ChannelProvider>
	);
}
