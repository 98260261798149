import create from 'zustand';
import { persist } from 'zustand/middleware';
const useAuthStore = create(persist((set) => ({
    jwt: null,
    setJWT: (jwt) => {
        set({
            jwt,
        });
    },
}), {
    name: 'ca-jwt-store',
    whitelist: ['jwt'],
}));
export default useAuthStore;
