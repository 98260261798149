import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Danger = ({ className }) => (_jsx("svg", { className: className, viewBox: "0 0 22 20", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "m20.1813609 10.4845488c.3590842-.64606507 1.2781939-.64606507 1.6372782 0l10.0593691 18.0988348c.3521908.6336622-.1002655 1.4166164-.8186391 1.4166164h-20.1187382c-.7183736 0-1.17082986-.7829542-.8186391-1.4166164zm-7.6356719 17.6142861h16.9076827l-8.4533717-15.2093216zm8.454311-2.1388109c-.6484261 0-1.1740794-.531988-1.1740794-1.1882283s.5256533-1.1882282 1.1740794-1.1882282 1.1740794.5319879 1.1740794 1.1882282-.5256533 1.1882283-1.1740794 1.1882283zm0-8.3175977c.5187409 0 .9392635.4205226.9392635.9392635v2.8743859c0 .518741-.4205226.9392636-.9392635.9392636s-.9392635-.4205226-.9392635-.9392636v-2.8743859c0-.5187409.4205226-.9392635.9392635-.9392635z", fill: "currentColor", transform: "translate(-10 -10)" }) }));
Danger.propTypes = {
    className: PropTypes.string,
};
Danger.defaultProps = {
    className: '',
};
export default Danger;
