import { jsx as _jsx } from "react/jsx-runtime";
import cn from '../../utils/cn';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton';
const RadioGroup = ({ value, options, className, onChange }) => {
    return (_jsx("div", { className: cn('flex flex-col', className.root), children: options.map((o) => (_jsx(RadioButton, { checked: value === o.value, label: o.label, onChange: () => onChange(o.value), className: cn(value === o.value ? className.active : className.radio) ||
                undefined }, o.value))) }));
};
RadioGroup.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    className: PropTypes.shape({
        root: PropTypes.string,
        radio: PropTypes.string,
        active: PropTypes.string,
    }),
    onChange: PropTypes.func,
};
RadioGroup.defaultProps = {
    value: '',
    options: [],
    className: { root: 'space-y-1' },
    onChange: () => { },
};
export default RadioGroup;
