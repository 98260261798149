import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Tooltip from '../Tooltip/Tooltip';
const MissingValue = (props) => {
    let jsx = (_jsxs("span", { className: "border border-red-500 rounded font-mono px-1 py-0.5 text-xs text-ca-black", children: [_jsx("span", { className: "text-ca-destructive mr-1", children: "?" }), "Missing value"] }));
    if (props.tooltip) {
        jsx = _jsx(Tooltip, { content: props.tooltip, children: jsx });
    }
    return jsx;
};
export default MissingValue;
