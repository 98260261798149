import { Navigate, Route, Routes } from 'react-router-dom';

import { useState } from 'react';

import { FilterMap } from 'components/Filter/FullFilterMenu.types';
import ConditionalFeatureRoute from 'routing/components/ConditionalFeatureRoute';
import ActualsLayout from './ActualsLayout';
import ActualsInventory from './views/ActualsInventory';
import ActualsSales from './views/ActualsSales';
import ActualsYoYComparison from './views/ActualsYoYComparison';

const actualsRouting = (
	<Route
		path="actuals/*"
		element={
			<ConditionalFeatureRoute feature="actuals" renderMethod="children">
				<ActualsRouting />
			</ConditionalFeatureRoute>
		}
	/>
);

function ActualsRouting() {
	const [filters, setFilters] = useState<FilterMap>(new Map());
	return (
		<Routes>
			<Route
				element={<ActualsLayout filters={filters} setFilters={setFilters} />}
			>
				<Route index element={<Navigate to="inventory" />} />
				<Route
					path="inventory"
					element={<ActualsInventory filters={filters} />}
				/>
				<Route path="sales" element={<ActualsSales filters={filters} />} />
				<Route
					path="yoycomparison"
					element={<ActualsYoYComparison filters={filters} />}
				/>
			</Route>
		</Routes>
	);
}

export default actualsRouting;
