import { useEffect, useRef } from 'react';

import { GET_CUMULIO_STRATEGY_DETAILS_SSO } from 'api/reports';
import filterMapToSearchParams from 'components/Filter/filterUtils';
import {
	Button,
	LoadingSpinner,
	RefreshCumulioDashboardButton,
} from 'crunch-components';
import useStrategyStore from 'hooks/useStrategyStore';
import { useEventCallback } from '../../strategy/v2/hooks';
import useMarkdownTransferModal from '../hooks/useMarkdownTransferModal';
import FullCumulioDashboard from 'components/FullCumulioDashboard/FullCumulioDashboard';
import { useLocation } from 'react-router-dom';
import RecommendationsHeader from '../RecommendationsHeader';

const StrategyDetails = () => {
	const location = useLocation();
	const { activeStrategy, setActiveStrategy, strategies, filters } =
		useStrategyStore();
	const cumulioRef = useRef(null);
	const {
		isPossible: transferIsPossible,
		transferInProgress,
		openModal: openTransferModal,
	} = useMarkdownTransferModal(filters, cumulioRef);

	return (
		<>
			<RecommendationsHeader />
			{transferIsPossible && (
				<div className="flex justify-end items-end ml-[auto] gap-2 -mt-10">
					<Button
						variant="primary"
						size="small"
						disabled={transferInProgress}
						onClick={openTransferModal}
					>
						<span className="h-3.5 inline-flex items-center">
							{transferInProgress ? (
								<div>
									<span>Transfering...</span>
									<LoadingSpinner variant="sm" />
								</div>
							) : (
								<span>Transfer markdowns</span>
							)}
						</span>
					</Button>
					<RefreshCumulioDashboardButton
						dashboardRef={cumulioRef}
						reloadType="refreshData"
					/>
				</div>
			)}
			<FullCumulioDashboard
				ref={cumulioRef}
				queryKey={[
					activeStrategy,
					'cumulio-recommendations-overview',
					Object.fromEntries(filters),
				]}
				ssoQuery={() =>
					GET_CUMULIO_STRATEGY_DETAILS_SSO(
						activeStrategy,
						filterMapToSearchParams(filters)
					)
				}
				noRefreshButton
				showLoading
			/>
		</>
	);
};

export default StrategyDetails;
