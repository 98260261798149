/* eslint-disable no-plusplus */
import dayjs, { Dayjs } from 'dayjs';
import relativetime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import { useEffect, useState } from 'react';

dayjs.extend(relativetime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: '%d seconds',
		m: 'a minute',
		mm: '%d minutes',
		h: 'an hour',
		hh: '%d hours',
		d: 'a day',
		dd: '%d days',
		M: 'a month',
		MM: '%d months',
		y: 'a year',
		yy: '%d years',
	},
});

const rerenderFrequency = [
	{ tresholdMs: 0, intervalMs: 5_000 },
	{ tresholdMs: 45_000, intervalMs: 60_000 },
	{ tresholdMs: 3_600_000, intervalMs: 3_600_000 },
] as const;

const getInterval = (time: Dayjs) => {
	const elapsedTimeMs = Date.now() - time.valueOf();

	for (let i = rerenderFrequency.length - 1; i >= 0; i--) {
		if (elapsedTimeMs >= rerenderFrequency[i].tresholdMs) {
			return rerenderFrequency[i].intervalMs;
		}
	}

	return rerenderFrequency[0].intervalMs;
};

const TimeSince = (props: { time: Dayjs }) => {
	const [formattedTimeSince, setFormattedTimeSince] = useState<string>(() =>
		props.time.fromNow(),
	);

	useEffect(() => {
		const interval = getInterval(props.time);
		const updateState = () => {
			setFormattedTimeSince(props.time.fromNow());
		};
		const intervalId = setInterval(updateState, interval);
		updateState();

		return () => clearTimeout(intervalId);
	}, [props.time]);

	return <span>{formattedTimeSince}</span>;
};

export default TimeSince;
