import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import cn from '../../utils/cn';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
const Alert = ({ value, type }) => {
    if (!value)
        return null;
    return (_jsx(Transition, { appear: true, show: true, as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", children: _jsxs("div", { className: cn('flex items-center p-3 bg-opacity-20 rounded-lg font-bold text-sm', type === 'error' && 'bg-ca-red text-ca-red', type === 'success' && 'bg-ca-green text-ca-green'), children: [type === 'error' &&
                    {
                    /* <DangerIcon className="h-5 mr-3" /> */
                    }, _jsx("span", { children: value })] }) }));
};
Alert.propTypes = {
    value: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['error', 'success']).isRequired,
};
Alert.defaultProps = {
    value: '',
    type: 'success'
};
export default Alert;
