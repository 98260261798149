import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '../Button/Button';
import RepeatIcon from '../Icons/Repeat';
const RefreshCumulioDashboardButton = (props) => {
    return (_jsxs(Button, { className: "inline-flex items-center", variant: "primary", size: "small", onClick: () => {
            switch (props.reloadType) {
                case 'hardReload':
                    props.onHardReload();
                    return;
                case 'reload':
                    props.dashboardRef.current?.reloadDashboard();
                    return;
                case 'refreshData':
                    props.dashboardRef.current?.refreshData();
                    return;
                default:
                    throw new Error('[RefreshCumulioDashboardButton] No reload type given. Fix this');
            }
        }, children: ["Reload dashboard ", _jsx(RepeatIcon, { className: "h-3.5 ml-2" })] }));
};
export default RefreshCumulioDashboardButton;
