import { LuzmoDashboardComponent } from '@luzmo/react-embed';
import {
	LinearProgress,
	RefreshCumulioDashboardButton,
} from 'crunch-components';
import React, { useEffect, useRef } from 'react';
import useChannelQuery from '../../hooks/channels/useChannelQuery';

const MemoizedDashboard = React.memo(
	({ dashboardId, cumulioRef, authKey, authToken }) => {
		return (
			<LuzmoDashboardComponent
				dashboardId={dashboardId}
				ref={cumulioRef}
				authKey={authKey}
				authToken={authToken}
				loaderBackground="#F5F5F7"
				loaderFontColor="transparent"
				loaderSpinnerColor="#6111C7"
				loaderSpinnerBackground="transparent"
				itemsRendered={(e) => console.log('itemsRendered', e)}
				load={(e) => console.log('loaded', e)}
			/>
		);
	},
	() => {
		return true;
	}
);

const FullCumulioDashboard = React.forwardRef(
	(
		{
			queryKey,
			ssoQuery,
			showLoading = true,
			noRefreshButton = false,
			hardReload = false,
		},
		ref
	) => {
		const {
			data,
			isLoading,
			isFetching: isDataFetching,
			queryKey: populatedQueryKey,
		} = useChannelQuery(queryKey, ssoQuery, {
			refetchOnMount: true,
			refetchOnWindowFocus: true,
			cacheTime: 60 * 5 * 1000,
			staleTime: 60 * 5 * 1000,
		});

		const cumulioRef = useRef(null);

		React.useImperativeHandle(
			ref,
			() => {
				return {
					getData: (cumulioItemId) => {
						if (cumulioRef.current && cumulioItemId) {
							return cumulioRef.current.getData(cumulioItemId);
						}
						return null;
					},
					getFilters: () => {
						if (cumulioRef.current) {
							return cumulioRef.current.getFilters();
						}
						return null;
					},
					reloadDashboard: () => {
						if (cumulioRef?.current?.reloadDashboard) {
							return cumulioRef.current.reloadDashboard();
						}
						return Promise.resolve();
					},
					refreshData: () => {
						if (cumulioRef?.current?.refreshData) {
							return cumulioRef.current.refreshData();
						}
						return Promise.resolve();
					},
					setAuthorization: (id, token) => {
						if (cumulioRef?.current?.setAuthorization) {
							return cumulioRef.current.setAuthorization(id, token);
						}
						return Promise.resolve();
					},
				};
			},
			[]
		);

		return (
			<>
				{showLoading && (
					<div className="absolute left-32 right-0 top-0">
						<LinearProgress visible={isDataFetching} />
					</div>
				)}
				{!isLoading && data?.dashboard_id && data?.id && data?.token && (
					<div className="mt-2">
						{!noRefreshButton && (
							<div className="flex justify-end -mt-10">
								{hardReload ? (
									<RefreshCumulioDashboardButton
										dashboardRef={cumulioRef}
										reloadType="hardReload"
										queryKeyToReset={populatedQueryKey}
									/>
								) : (
									<RefreshCumulioDashboardButton
										dashboardRef={cumulioRef}
										reloadType="reload"
									/>
								)}
							</div>
						)}

						<MemoizedDashboard
							cumulioRef={cumulioRef}
							authKey={data.id}
							authToken={data.token}
							dashboardId={data.dashboard_id}
						/>
					</div>
				)}
			</>
		);
	}
);

export default FullCumulioDashboard;
