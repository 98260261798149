import api from 'api/api';
import { Button, Tooltip, useToast } from 'crunch-components';
import { ReactNode } from 'react';

export type UploadsDownloadButtonProps = {
	uploaderId: string;
	type: 'processed-file' | 'example-file';
	disabled?: boolean;
	children: NonNullable<ReactNode>;
	tooltip: string;
};

// In theory it could be an improvement for the button to take an URL instead of a type. That way
// this button could be re-used less specific uploads.
async function download(
	uploaderId: string,
	type: UploadsDownloadButtonProps['type'],
	toast: any, // TODO: add support for types on useToast
) {
	let resp: Response;
	try {
		resp = await api.post(`api/v2/uploaders/${uploaderId}/download-${type}`);
	} catch (error) {
		toast('File currently unavailable', { type: 'error' });
		return;
	}

	const blob = await resp.blob();
	const href = window.URL.createObjectURL(blob);

	// TODO: Is there truly not a neat-er way?
	const contentDisposition = resp.headers.get('content-disposition');
	const filename =
		contentDisposition && contentDisposition.includes('filename=')
			? contentDisposition.split('filename=')[1].replace(/"/g, '')
			: 'download.csv';

	const a = document.createElement('a');
	a.href = href;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
}

const UploadsDownloadButton = ({
	uploaderId,
	type,
	disabled,
	tooltip,
	children,
}: UploadsDownloadButtonProps) => {
	const { show } = useToast.getState();

	return (
		<Tooltip content={tooltip}>
			<Button
				size="small"
				variant="link"
				disabled={disabled === true}
				onClick={() => download(uploaderId, type, show)}
			>
				{children}
			</Button>
		</Tooltip>
	);
};

export default UploadsDownloadButton;
