import { Navigate, Route } from 'react-router-dom';
import ConditionalFeatureRoute from 'routing/components/ConditionalFeatureRoute';
import StrategyLayout from './StrategyLayout';
import { ObjectivesPage } from './v2/ObjectivesPage';
import { StrategiesPage } from './v2/StrategiesPage';
import BusinessRulesOverview from './views/BusinessRules/OverviewV2';

const legacyRoutes = [
	<Route
		key="components"
		path="components"
		element={<Navigate to="strategies" />}
	/>,
	<Route
		key="strategies/:strategyId"
		path="strategies/:strategyId"
		element={<Navigate to="strategies" />}
	/>,
	<Route
		key="categories/create"
		path="categories/create"
		element={<Navigate to="objectives" />}
	/>,
	<Route
		key="business-rules/:businessRuleId/edit"
		path="business-rules/:businessRuleId/edit"
		element={<Navigate to="business-rules" />}
	/>,
	<Route
		key="business-rule-edit"
		path="business-rules/create"
		element={<Navigate to="business-rules" />}
	/>,
] as const;

const strategyRouting = (
	<Route
		path="strategy"
		element={
			<ConditionalFeatureRoute feature="strategy" renderMethod="children">
				<StrategyLayout />
			</ConditionalFeatureRoute>
		}
	>
		<Route index element={<Navigate to="strategies" />} />
		<Route path="strategies" element={<StrategiesPage />} />
		<Route path="objectives" element={<ObjectivesPage />} />
		<Route path="business-rules" element={<BusinessRulesOverview />} />
		{legacyRoutes}
	</Route>
);

export default strategyRouting;
