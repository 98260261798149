import { Tooltip } from 'crunch-components';
import { ReactNode } from 'react';
import { UploadValidationErrors } from '../types/uploads';
import UploadsValidationCategory from './UploadsValidationTooltipCategory';

export type UploadsValidationTooltipProps = {
	validationErrors?: UploadValidationErrors;
	children: NonNullable<ReactNode>;
};

function getCount(v: UploadValidationErrors): number {
	return (
		(v.column_match_errors?.length ?? 0) +
		(v.column_values_errors?.length ?? 0) +
		(v.unique_values_errors?.length ?? 0) +
		(v.required_values_errors?.length ?? 0)
	);
}

export default function UploadsValidationTooltip({
	validationErrors,
	children,
}: UploadsValidationTooltipProps) {
	if (validationErrors === undefined || getCount(validationErrors) === 0) {
		return <>{children}</>;
	}

	const errors = (
		<div className="text-base p-4 leading-8">
			<ul className="list-none list-outside">
				<UploadsValidationCategory
					failText="Not all columns are present"
					successText="All columns are present"
					errors={validationErrors.column_match_errors}
				/>
				<UploadsValidationCategory
					failText="Not all columns contain valid values"
					successText="All columns contain valid values"
					errors={validationErrors.column_values_errors}
				/>
				<UploadsValidationCategory
					failText="Required columns are missing data"
					successText="Required columns are not missing data"
					errors={validationErrors.required_values_errors}
				/>
				<UploadsValidationCategory
					failText="Unique columns have duplicates"
					successText="Unique columns have no duplicates"
					errors={validationErrors.unique_values_errors}
				/>
			</ul>
		</div>
	);

	return (
		<Tooltip placement="bottom-start" content={errors}>
			{children}
		</Tooltip>
	);
}
