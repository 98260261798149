import { useQueryClient } from 'react-query';
import constructChannelQueryKey from 'utils/channelUtils';
import useChannelStore from './useChannelStore';

const useChannelPrefetch = () => {
	const queryClient = useQueryClient();
	const activeChannel = useChannelStore((s) => s.activeChannel);

	return (queryKey, ...params) => {
		let key = queryKey;
		if (!Array.isArray(queryKey)) {
			key = [queryKey];
		}

		queryClient.prefetchQuery(
			constructChannelQueryKey(activeChannel, key),
			...params,
		);
	};
};

export default useChannelPrefetch;
