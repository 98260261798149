// @ts-nocheck
import { Transition } from '@headlessui/react';
import { cn } from 'crunch-components';
import dayjs from 'dayjs';
import download from 'downloadjs';
import mime from 'mime/lite';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
	Button,
	CircularProgress,
	Dropdown,
	LinearProgress,
	RadioGroup,
	TableHeaderButton,
	TableV2,
	Text,
	TextInput,
	Tooltip,
} from 'crunch-components';

import {
	GET_RECOMMENDATIONS_SCHEMA,
	RecommendationsDataType,
} from 'api/recommendations-v2';
import filterMapToSearchParams from 'components/Filter/filterUtils';
import useChannelPrefetch from 'hooks/channels/useChannelPrefetch';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import useChannelStore from 'hooks/channels/useChannelStore';
import constructChannelQueryKey from 'utils/channelUtils';
import {
	CREATE_RECOMMENDATION,
	GET_RECOMMENDATIONS,
	GET_RECOMMENDATIONS_EXPORT,
} from '../../../api/recommendations';
import { GET_CUMULIO_RECOMMENDATIONS_PRODUCT_SSO } from '../../../api/reports';
import useDebounce from '../../../hooks/useDebounce';
import { useRecommendationsHeadings as useHeadings } from '../../../hooks/useHeadings';
import useStrategyStore from '../../../hooks/useStrategyStore';
import BusinessRuleSet from '../components/BusinessRuleSet';
import RecommendationsDetail from '../components/RecommendationsDetail';
import RecommendationsHeader from '../RecommendationsHeader';

const ProductDetails = () => {
	const [pagination, setPagination] = useState({ page: 1, size: 50 });
	const [sort, setSort] = useState({
		key: 'attainable_increase_objective',
		direction: 'desc',
	});
	const [selected, setSelected] = useState([]);
	const [open, setOpen] = useState([]);
	const [selectAll, setSelectAll] = useState(null); // `null` = should show popup, true = all selected, false = only current selection
	const { activeStrategy, filters } = useStrategyStore();
	const [prevFilters, setPrevFilters] = useState(filters);
	const [search, setSearch] = useState('');
	const {
		disabledHeadings,
		toggleHeading,
		addToDefaultDisabledHeading,
		resetHeadings,
	} = useHeadings();
	const debouncedSearch = useDebounce(
		search,
		500,
		// @ts-ignore
		(v) => v === '' || v.length >= 3,
	);
	const prefetchQuery = useChannelPrefetch();

	const queryClient = useQueryClient();

	const { activeChannel } = useChannelStore();

	const baseQueryKey = ['recommendations', activeStrategy];
	const activeRecommendationQueryKey = constructChannelQueryKey(
		activeChannel,
		baseQueryKey,
	);

	const { isLoading: isSchemaLoading, data: schema = [] } = useChannelQuery<
		RecommendationsDataType,
		Error,
		RecommendationsDataType
	>('recommendations-schema', GET_RECOMMENDATIONS_SCHEMA, {
		staleTime: 5 * 60 * 1000,
		onSuccess: (
			data: Awaited<ReturnType<typeof GET_RECOMMENDATIONS_SCHEMA>>,
		) => {
			data
				.filter((col) => col.hidden_by_default)
				.forEach((col) => addToDefaultDisabledHeading(col.id));
			resetHeadings();
		},
	});

	// When filters change: reset selection and page number.
	// useState (instead of useEffect) updates component during rendering. Refer to:
	// https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
	if (filters !== prevFilters) {
		setPrevFilters(filters);
		setPagination({ ...pagination, page: 1 });
		setSelected([]);
		setSelectAll(null);
	}

	// convert id to bq_name for sorting filter
	const getSortParams = () => {
		const heading = schema.find(({ id }) => id === sort.key);

		if (!heading) {
			return null;
		}

		return ['sort', `${heading.id}:${sort.direction}`];
	};

	const { isFetching: isDataFetching, data } = useChannelQuery(
		[
			...baseQueryKey,
			pagination,
			Object.fromEntries(filters),
			sort,
			debouncedSearch,
		],
		() => {
			const sortParams = getSortParams();

			return GET_RECOMMENDATIONS(
				activeStrategy,
				new URLSearchParams([
					['page', pagination.page],
					['size', pagination.size],
					['search_filter', debouncedSearch],
					...(sortParams ? [sortParams] : []),
					...filterMapToSearchParams(filters),
				]),
			);
		},
		{
			staleTime: 5 * 60 * 1000,
			keepPreviousData: true,
		},
	);

	useEffect(() => {
		// automatically reset select-all pop-up state when
		// selecting less than a full page
		if (selected?.length < pagination?.size) {
			setSelectAll(null);
		}
	}, [selected]);

	const headings = useMemo(
		() =>
			schema.map((s) => ({
				id: s.id,
				label: s.name,
				tooltip: s.tooltip,
				sortable: s.sortable,
				// @ts-ignore
				enabled: !disabledHeadings.includes(s.id),
				align: 'center',
				...([
					'attainable_increase_turnover',
					'attainable_increase_margin',
					'attainable_increase_objective',
					// @ts-ignore
				].includes(s.id)
					? { maxWidth: '100px' }
					: {}),
			})),
		[schema, disabledHeadings],
	);

	const handleSortChange = (s) => {
		setSort(s);
		// go back to page 1
		setPagination((p) => ({ ...p, page: 1 }));
		// clear the current selection
		setSelected([]);
		// clear the open rows
		setOpen([]);
	};

	const handlePaginationChange = (pag) => {
		setPagination(pag);
		// clear the open rows
		setOpen([]);
	};

	const { mutate: overwriteRecommendation } = useMutation(
		CREATE_RECOMMENDATION,
		{
			onMutate: async (opp) => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(activeRecommendationQueryKey);

				// override recommendations to have a loading state set to `true` for the overwritten opp
				queryClient.setQueriesData(activeRecommendationQueryKey, (old) => ({
					...old,
					items: (old?.items || []).map((o) =>
						o?.product_id === opp?.product_id
							? { ...o, ...opp, loading: true }
							: o,
					),
				}));
			},
			onSettled: (d, _err, opp) => {
				// override recommendations with the new date for the overwritten opp and cancel the loading state
				queryClient.setQueriesData(activeRecommendationQueryKey, (old) => ({
					...old,
					items: (old?.items || []).map((o) =>
						o?.product_id === opp?.product_id ? d : o,
					),
				}));
			},
		},
	);

	const { isLoading: isExportLoading, mutate: exportRecommendations } =
		useMutation(
			() =>
				GET_RECOMMENDATIONS_EXPORT(
					activeStrategy,
					selectAll,
					new URLSearchParams([
						// add filters if export-all
						// add specific product_ids if not export-all
						...(selectAll
							? filterMapToSearchParams(filters)
							: [['product_ids', selected.join(',')]]),
					]),
				),
			{
				onSuccess: (blob) => {
					download(blob, `export.${mime.getExtension(blob.type)}`, blob.type);
				},
			},
		);

	const handleRowOverwrite = (opp) => {
		overwriteRecommendation({
			strategyId: activeStrategy,
			product_id: opp?.product_id,
			markdown_type: opp?.markdown_type,
			overwrite_markdown:
				opp?.markdown_type === 'Custom' ? opp?.overwrite_markdown : null,
		});
	};

	const handleIdClicked = useCallback(
		(id) => {
			if (open.includes(id)) {
				setOpen((o) => o.filter((oId) => oId !== id));
			} else {
				setOpen((o) => [...o, id]);
			}
		},
		[open],
	);

	const prefetchRow = (oId) => {
		prefetchQuery(['product', oId], () => GET_PRODUCT(oId), {
			staleTime: 5 * 60 * 1000,
		});
		prefetchQuery(
			['product-markdown-recommendations', activeStrategy, oId],
			() => GET_PRODUCT_MARKDOWN_RECOMMENDATIONS(activeStrategy, oId),
			{ staleTime: 5 * 60 * 1000 },
		);
		prefetchQuery(
			['cumulio-recommendations-product', activeStrategy, oId],
			() => GET_CUMULIO_RECOMMENDATIONS_PRODUCT_SSO(activeStrategy, oId),
			{ staleTime: 5 * 60 * 1000 },
		);
	};

	const renderOpen = useCallback(
		(oId) => {
			const recommendation = data?.items?.find((o) => o?.product_id === oId);

			if (!recommendation) return null;

			return (
				<RecommendationsDetail
					id={recommendation?.product_id}
					onClose={() => setOpen((o) => o.filter((id) => id !== oId))}
				/>
			);
		},
		[data],
	);

	const renderCell = useCallback(
		(row, columnId) => {
			const { field_type: fieldType, allowed_markdowns: allowedMarkdowns } =
				schema.find(({ id }) => id === columnId);

			if (fieldType === 'date') {
				return dayjs(row?.[columnId]).format('DD/MM/YYYY');
			}

			if (fieldType === 'image') {
				return row?.[columnId] ? (
					<span
						onMouseOver={() => prefetchRow(row?.product_id)}
						onFocus={() => {}}
					>
						<Button
							variant="unstyled"
							onClick={() => handleIdClicked(row?.product_id)}
						>
							<img className="inline-block w-16" src={row?.[columnId]} alt="" />
						</Button>
					</span>
				) : null;
			}

			if (fieldType === 'custom_markdown') {
				const overwriteMarkdown =
					row?.markdown_type === 'Custom' ? row?.overwrite_markdown : null;
				return (
					<div className="h-full flex justify-center text-left relative">
						<RadioGroup
							options={[
								{ value: 'Current', label: 'Current' },
								{ value: 'Optimal', label: 'Optimal' },
								{ value: 'Custom', label: 'Custom' },
							]}
							value={row?.markdown_type}
							onChange={(type) =>
								handleRowOverwrite({
									...row,
									markdown_type: type,
									overwrite_markdown:
										row?.overwrite_markdown || allowedMarkdowns[0],
								})
							}
							className={{
								root: 'space-y-3',
								radio: 'text-xs',
								active: 'text-xs font-bold',
							}}
						/>
						<div className="h-full ml-4 flex flex-col align-baseline">
							<Text size="text-xs" className="leading-none py-0.5">
								{Math.round(row?.current_markdown * 100)}%
							</Text>
							<Text size="text-xs" className="leading-none mt-3 mb-2 py-0.5">
								{Math.round(row?.optimal_markdown * 100)}%
							</Text>
							<Dropdown
								onChange={(val) =>
									handleRowOverwrite({
										...row,
										markdown_type: 'Custom',
										overwrite_markdown: val,
									})
								}
								value={overwriteMarkdown}
								options={(allowedMarkdowns?.includes(overwriteMarkdown)
									? allowedMarkdowns
									: [...allowedMarkdowns, overwriteMarkdown].sort()
								)?.map((n) => ({
									value: n,
									label: `${Math.round(n * 100)}%`,
								}))}
								size="small"
								dropUpDown="up"
								className="-mt-px"
							/>
						</div>
					</div>
				);
			}

			if (
				fieldType === 'ati_turnover' ||
				fieldType === 'ati_margin' ||
				fieldType === 'ati_objective'
			) {
				const optimal = row?.[`${columnId}_optimal`];
				const overwrite =
					row?.[`${columnId}_overwrite`] ||
					`${optimal?.replace('-', '').charAt(0)}0`; // get currency from optimal value + add 0 when no overwrite value was present

				if (row?.loading) {
					return (
						<div className="flex justify-center">
							<CircularProgress size="small" />
						</div>
					);
				}

				return (
					<>
						{optimal && (
							<Text className="font-bold">
								{optimal.toString().startsWith('-') && '- '}
								<span
									className={cn(
										row?.markdown_type !== 'Optimal' &&
											'line-through decoration-2',
									)}
								>
									{optimal.toString().replace('-', '')}
								</span>
							</Text>
						)}
						{row?.markdown_type !== 'Optimal' && (
							<Text className="mt-2 font-bold">
								{overwrite.toString().replace('-', '- ')}
							</Text>
						)}
					</>
				);
			}
			if (
				fieldType === 'ati_turnover_percentage' ||
				fieldType === 'ati_margin_percentage' ||
				fieldType === 'ati_objective_percentage'
			) {
				const optimal = row?.[`${columnId}_optimal`];
				const overwrite = row?.[`${columnId}_overwrite`] || `%0`; // get currency from optimal value + add 0 when no overwrite value was present

				if (row?.loading) {
					return (
						<div className="flex justify-center">
							<CircularProgress size="small" />
						</div>
					);
				}

				return (
					<>
						{optimal && (
							<Text className="font-bold">
								{optimal.toString().startsWith('-') && '- '}
								<span
									className={cn(
										row?.markdown_type !== 'Optimal' &&
											'line-through decoration-2',
									)}
								>
									{optimal.toString().replace('-', '')}
								</span>
							</Text>
						)}
						{row?.markdown_type !== 'Optimal' && (
							<Text className="mt-2 font-bold">
								{overwrite.toString().replace('-', '- ')}
							</Text>
						)}
					</>
				);
			}

			if (fieldType === 'id') {
				return (
					<span
						onMouseOver={() => prefetchRow(row?.[columnId])}
						onFocus={() => {}}
					>
						<Button
							variant="unstyled"
							className="hover:underline"
							onClick={() => handleIdClicked(row?.[columnId])}
						>
							{row?.[columnId]?.toString()}
						</Button>
					</span>
				);
			}

			if (fieldType === 'business_rules') {
				return <BusinessRuleSet row={row} colId={columnId} />;
			}

			return row?.[columnId]?.toString();
		},
		[data, open],
	);

	return (
		<>
			<RecommendationsHeader />
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress
					visible={isDataFetching || isSchemaLoading || isExportLoading}
				/>
			</div>
			<div className="-mt-10 flex items-center justify-end gap-6">
				<div className="flex items-center gap-2">
					<Text className="font-bold" size="text-xs">
						{selectAll ? data?.total : selected.length} selected
					</Text>
					<Tooltip
						content={
							!selected.length && <>Select some products to export them.</>
						}
					>
						<span>
							<Button
								size="small"
								disabled={!selected.length}
								onClick={exportRecommendations}
							>
								Export
							</Button>
						</span>
					</Tooltip>
				</div>
				<div className="flex items-center justify-end gap-2">
					<TextInput
						id="search"
						size="small"
						placeholder="Search product code"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
					<TableHeaderButton
						headings={headings}
						disabled={false}
						loading={isSchemaLoading}
						onChange={toggleHeading}
						onReset={resetHeadings}
					/>
				</div>
			</div>
			<div className="relative">
				<Transition
					show={selected.length === pagination?.size && selectAll === null}
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<div className="absolute z-10 left-0 w-96 p-5 mt-9 ml-12 bg-ca-black bg-opacity-90 rounded">
						<Text size="text-xs" type="inverted">
							All {pagination?.size} products on this page have been selected.
						</Text>
						<Text size="text-xs" type="inverted" className="mt-2">
							Would you like to select{' '}
							<strong>all {data?.total} products</strong> instead?
						</Text>
						<div className="flex justify-between items-center mt-4">
							<Button
								size="small"
								variant="secondary"
								onClick={() => setSelectAll(false)}
							>
								Keep current selection
							</Button>
							<Button size="small" onClick={() => setSelectAll(true)}>
								Select all products
							</Button>
						</div>
					</div>
				</Transition>
				<div className="mt-6">
					<TableV2
						headings={headings.filter(({ enabled }) => enabled)}
						rows={data?.items}
						rowKey="product_id"
						renderCell={renderCell}
						pagination={{
							currentPage: pagination?.page,
							items: data?.total,
							itemsPerPage: pagination?.size,
							itemsPerPageOptions: [10, 25, 50, 100],
						}}
						onPageChange={(page) =>
							handlePaginationChange((p) => ({ ...p, page }))
						}
						onItemsPerPageChange={(size) =>
							handlePaginationChange({ size, page: 1 })
						}
						sort={sort}
						onSortChange={handleSortChange}
						loading={isDataFetching}
						itemsLoading={pagination?.size}
						emptyState="No products found for the selected filters..."
						selectable
						selected={selected}
						onSelectedChange={setSelected}
						open={open}
						renderOpen={renderOpen}
					/>
				</div>
			</div>
		</>
	);
};

export default ProductDetails;
