import * as React from 'react';

import {
	BATCH_CREATE_STRATEGY,
	BATCH_UPDATE_STRATEGY,
	GET_ALL_BUSINESS_RULES,
	GET_ALL_OBJECTIVES,
	GET_STRATEGY_BUSINESS_RULES,
	GET_STRATEGY_OBJECTIVES,
} from 'api/strategies-v2';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import {
	Badge,
	Button,
	CircularProgress,
	cn,
	CompletedStepIcon,
	CrossIcon,
	CurrentStepIcon,
	ForwardIcon,
	IconButton,
	InputWithLabel,
	Modal,
	NextStepIcon,
	ProductIcon,
	SaveIcon,
	TextInput,
	useModal,
} from 'crunch-components';

import useChannelQueries from 'hooks/channels/useChannelQueries';
import useChannelStore from 'hooks/channels/useChannelStore';
import constructChannelQueryKey from 'utils/channelUtils';
import { PhasesDataType } from '../types/seasons';
import {
	GraphData,
	ModalType,
	ScenariosDataType,
	ScenariosForObjectiveType,
	Strategy,
	StrategyBusinessRulesDataType,
	StrategyObjectivesDataType,
} from '../types/strategies';
import { CheckboxGrid } from './CheckboxGrid';
import { checkboxReducer, initGrid } from './grid';
import { useEventCallback } from './hooks';
import { ObjectivesSlider } from './ObjectivesSlider';
import {
	ALL_OBJECTIVES_KEY,
	ALL_RULES_KEY,
	ObjectiveBasicDataType,
	RuleBasicDataType,
	STRATEGY_OBJECTIVES_KEY,
	STRATEGY_RULES_KEY,
	transformAllObjectives,
	transformAllRules,
} from './queries';
import {
	insertCustomScenario,
	ObjectiveFormData,
	replaceExistingScenario,
	transformObjectivesForSubmit,
	transformRulesForSubmit,
} from './utils';

const steps = [
	{
		id: 'name',
		name: 'Strategy Name',
		className: '',
		forwardButton: (
			<span className="flex flex-row">
				Objectives <ForwardIcon className="ml-1 h-3.5 w-auto" />
			</span>
		),
		backButton: 'Cancel',
	},
	{
		id: 'objectives',
		name: 'Objectives',
		className: '',
		forwardButton: (
			<span className="flex flex-row">
				Business rules <ForwardIcon className="ml-1 h-3.5 w-auto" />
			</span>
		),
		backButton: 'Back',
	},
	{
		id: 'rules',
		name: 'Business rules',
		className: '',
		forwardButton: (
			<span className="flex flex-row">
				Finish <SaveIcon className="ml-1 h-3.5 w-auto" />
			</span>
		),
		backButton: 'Back',
	},
] as const;

type StepIdType = (typeof steps)[number]['id'];

const titlesMap: {
	[K in ModalType]: { [SK in StepIdType]: { title: React.ReactNode } };
} = {
	create: {
		name: {
			title: (
				<div className="mb-7 mt-2">
					<p className="text-black text-2xl font-bold">Choose a name</p>
					<p className="text-ca-gray-500 text-sm">for your strategy</p>
				</div>
			),
		},
		objectives: {
			title: (
				<div className="mb-7 mt-2">
					<p className="text-black text-2xl font-bold">Determine objectives</p>
					<p className="text-ca-gray-500 text-sm">per group</p>
				</div>
			),
		},
		rules: {
			title: (
				<div className="mb-7 mt-2">
					<p className="text-black text-2xl font-bold">Apply business rules</p>
					<p className="text-ca-gray-500 text-sm">per phase</p>
				</div>
			),
		},
	},
	update: {
		name: {
			title: (
				<div className="mb-7 mt-2">
					<p className="text-black text-2xl font-bold">Update name</p>
					<p className="text-ca-gray-500 text-sm">for your strategy</p>
				</div>
			),
		},
		objectives: {
			title: (
				<div className="mb-7 mt-2">
					<p className="text-black text-2xl font-bold">Update objectives</p>
					<p className="text-ca-gray-500 text-sm">per group</p>
				</div>
			),
		},
		rules: {
			title: (
				<div className="mb-7 mt-2">
					<p className="text-black text-2xl font-bold">Update business rules</p>
					<p className="text-ca-gray-500 text-sm">per phase</p>
				</div>
			),
		},
	},
};

const getSteps = (type: ModalType) => {
	const titles = titlesMap[type];

	return steps.map((step) => {
		return { ...step, ...(titles[step.id] && titles[step.id]) };
	});
};

type StrategyModalProps = {
	phases: PhasesDataType;
	strategy: Strategy;
	defaultScenarios: ScenariosDataType;
	newStrategyDefault: ScenariosDataType[number];
	type: ModalType;
	onSuccess?: () => Promise<void>;
	onMutate?: () => Promise<void>;
};

interface FormValues {
	name: string;
	objectives: ObjectiveFormData[];
}

// used to init the form
const initialFormValues: FormValues = {
	name: '',
	objectives: [] as ObjectiveFormData[],
} as const;

export const StrategyModal = ({
	strategy,
	phases,
	defaultScenarios,
	newStrategyDefault,
	type: scenario,
	onSuccess,
	onMutate,
}: StrategyModalProps) => {
	const queryClient = useQueryClient();
	const { activeChannel } = useChannelStore();
	const dashboardQueryKey = constructChannelQueryKey(activeChannel, [
		'cumulio-scenario-overview',
	]);

	const stepsMerged = getSteps(scenario);
	const [scenariosForObjective, setScenariosForObjective] =
		React.useState<ScenariosForObjectiveType>({});
	const [currentStepId, setCurrentStepId] = React.useState<StepIdType>(
		stepsMerged[0].id,
	);
	const currentStep = stepsMerged.find((item) => item.id === currentStepId)!;
	const currentStepIndex = stepsMerged.findIndex(
		(item) => item.id === currentStepId,
	);

	const [gridState, dispatchGrid] = React.useReducer(
		checkboxReducer,
		initGrid({ rows: [], columns: [], scenario: 'create' }),
	);

	const { close: rawClose } = useModal();
	const close = () => {
		if (rawClose !== undefined) {
			rawClose();
		}
	};
	const {
		control,
		handleSubmit: doFormSubmission,
		formState: { errors },
		reset,
	} = useForm<FormValues>({ defaultValues: initialFormValues });

	const queries = useChannelQueries({
		queries: [
			{
				queryKey: [...STRATEGY_RULES_KEY, strategy.slug],
				queryFn: () =>
					scenario === 'update' ? GET_STRATEGY_BUSINESS_RULES(strategy.id) : [],
			},
			{
				queryKey: [...STRATEGY_OBJECTIVES_KEY, strategy.slug],
				queryFn: () =>
					scenario === 'update' ? GET_STRATEGY_OBJECTIVES(strategy.id) : [],
			},
			{
				queryKey: ALL_RULES_KEY,
				queryFn: () => (scenario === 'create' ? GET_ALL_BUSINESS_RULES() : []),
				select: transformAllRules,
			},
			{
				queryKey: ALL_OBJECTIVES_KEY,
				queryFn: () => (scenario === 'create' ? GET_ALL_OBJECTIVES() : []),
				select: transformAllObjectives,
			},
		],
	});
	const [
		{ data: rulesData },
		{ data: objectivesData },
		{ data: allRulesData },
		{ data: allObjectivesData },
	] = queries;

	const rules = (
		scenario === 'update'
			? ((rulesData as StrategyBusinessRulesDataType) ?? [])
			: ((allRulesData as RuleBasicDataType[]) ?? [])
	).map((item) => ({ ...item, name: item.title }));
	const objectives =
		scenario === 'update'
			? ((objectivesData as StrategyObjectivesDataType) ?? [])
			: ((allObjectivesData as ObjectiveBasicDataType[]) ?? []);

	/*  FORM SUBMISSION */
	// we have separate mutations for create and update
	const { isLoading: isCreateStrategyLoading, mutate: createStrategy } =
		useMutation(BATCH_CREATE_STRATEGY, {
			onMutate: async () => {
				await onMutate?.();
			},
			onSuccess: async () => {
				await onSuccess?.();
				close();
			},
		});

	const { isLoading: isUpdateStrategyLoading, mutate: updateStrategy } =
		useMutation(BATCH_UPDATE_STRATEGY, {
			onMutate: async () => {
				await onMutate?.();
			},
			onSuccess: async () => {
				await onSuccess?.();
				close();
			},
		});

	const nextStep = async (runSubmitForm: () => void) => {
		switch (true) {
			case currentStepIndex >= stepsMerged.length - 1: {
				return runSubmitForm();
			}
			default: {
				return setCurrentStepId(stepsMerged[currentStepIndex + 1].id);
			}
		}
	};

	const handleSubmit = async (data: FormValues) => {
		const { name, objectives: formObjectives } = data;

		// submit form
		const runSubmitForm = async () => {
			if (scenario === 'create') {
				const params = {
					name,
					rules: transformRulesForSubmit({ rules, phases, gridState }),
					objectives: transformObjectivesForSubmit({
						allScenarios: scenariosForObjective,
						formObjectives,
					}),
				};

				createStrategy(params);
			}
			if (scenario === 'update') {
				const params = {
					name,
					rules: transformRulesForSubmit({ rules, phases, gridState }),
					objectives: transformObjectivesForSubmit({
						allScenarios: scenariosForObjective,
						formObjectives,
					}),
					strategyId: strategy.id,
				};

				updateStrategy(params);
			}
		};

		queryClient.resetQueries({ queryKey: dashboardQueryKey });

		await nextStep(runSubmitForm);
	};

	const prevStep = () => {
		switch (true) {
			case currentStepIndex < 1: {
				close();
				return;
			}
			default: {
				setCurrentStepId(stepsMerged[currentStepIndex - 1].id);
			}
		}
	};

	const isFetching = queries.some((q) => q.isFetching);
	const isSaving = isUpdateStrategyLoading || isCreateStrategyLoading;

	// resets data in the modal when api requests are done
	const resetModalData = useEventCallback(() => {
		if (scenario === 'create') {
			const newScenarios = (objectives as ObjectiveBasicDataType[]).reduce<
				Record<string, ScenariosDataType>
			>((acc, item) => {
				// TODO not clear whether this is needed
				// acc[item.id] = updateScenarios({
				// 	scenarios: defaultScenarios,
				// 	customScenario: newStrategyDefault,
				// });
				acc[item.id] = defaultScenarios;
				return acc;
			}, {});

			reset({
				name: strategy.name,
				objectives: objectives.map((item) => ({
					id: item.id,
					intensity: newStrategyDefault.intensity_level,
				})),
			});
			setScenariosForObjective(newScenarios);

			dispatchGrid({
				type: 'RESET',
				payload: {
					state: initGrid({
						rows: rules,
						columns: phases,
						scenario: 'create',
					}),
				},
			});

			return;
		}

		// once we finish loading we reset data state

		const mergedObjectives = (objectives as StrategyObjectivesDataType).map(
			(item) => {
				const [mergedScenarios, newIntensityValue] = replaceExistingScenario({
					scenarios: defaultScenarios,
					existingScenario: item,
				});

				return [item.id, mergedScenarios, newIntensityValue] as const;
			},
			[] as [string, ScenariosDataType, number][],
		);

		const newScenarios = mergedObjectives.reduce(
			(acc, [id, scenarios]) => {
				acc[id] = scenarios;
				return acc;
			},
			{} as Record<string, ScenariosDataType>,
		);

		reset({
			name: strategy.name,
			objectives: mergedObjectives.map(([id, _, intensity]) => ({
				id,
				intensity,
			})),
		});
		setScenariosForObjective(newScenarios);

		dispatchGrid({
			type: 'RESET',
			payload: {
				state: initGrid({
					rows: rules,
					columns: phases,
					scenario: 'update',
				}),
			},
		});
	});

	// resets data in the modal when api requests are done
	React.useEffect(() => {
		if (isFetching) {
			return;
		}
		resetModalData();
	}, [isFetching]);

	const forms: {
		[K in StepIdType]: React.ReactNode;
	} = {
		name: (
			<InputWithLabel
				label="Strategy name"
				htmlFor="name"
				labelClassName={cn('w-64 mb-1')}
				className="flex-col !items-start"
			>
				<Controller<FormValues, 'name'>
					name="name"
					control={control}
					rules={{ required: 'Required field' }}
					render={({ field }) => {
						return (
							<TextInput
								id="title"
								type="text"
								placeholder="Enter name"
								className="w-full sm:w-64"
								error={errors?.name?.message}
								{...field}
							/>
						);
					}}
				/>
			</InputWithLabel>
		),
		objectives: objectives.map((item, idx) => {
			return (
				<div key={item.id}>
					<div className="text-ca-gray-500 text-sm">
						{item.name}
						<Badge className="ml-3">
							<ProductIcon className="h-2.5 w-auto mr-2" />{' '}
							{item.products_count}
						</Badge>
					</div>
					<Controller<FormValues, `objectives.${number}.intensity`>
						name={`objectives.${idx}.intensity`}
						control={control}
						render={({ field }) => {
							const options = scenariosForObjective[item.id] ?? [];
							const updateScenariosForObjective = (customData: GraphData) => {
								const [newOptions, newValue] = insertCustomScenario({
									scenarios: options,
									customData,
								});

								setScenariosForObjective({
									...scenariosForObjective,
									[item.id]: newOptions,
								});
								field.onChange(newValue);
							};

							return (
								<ObjectivesSlider
									value={field.value}
									options={options}
									onChange={field.onChange}
									updateScenariosForObjective={updateScenariosForObjective}
								/>
							);
						}}
					/>
				</div>
			);
		}),
		rules: (
			<CheckboxGrid
				rows={rules}
				columns={phases}
				gridState={gridState}
				dispatchGrid={dispatchGrid}
				className="mb-8 mt-4"
			/>
		),
	};

	let modalContent = (
		<div className="max-h-[80vh] flex flex-row">
			<div className="flex flex-col w-[200px] bg-ca-silver rounded-md items-start flex-shrink-0 px-5 justify-center">
				{scenario === 'create' ? (
					<Badge variant="success" className="font-normal ml-2">
						Creating
					</Badge>
				) : (
					<Badge variant="neutral" className="font-normal ml-2">
						Edit
					</Badge>
				)}
				<p className="text-black font-bold text-sm mb-4 ml-2">
					{scenario === 'create' ? 'new strategy' : 'strategy'}
				</p>
				{stepsMerged.map((step, index) => {
					let icon;

					switch (true) {
						case index === currentStepIndex: {
							icon = <CurrentStepIcon className="h-4 w-auto m-1" />;
							break;
						}

						case index < currentStepIndex: {
							icon = <CompletedStepIcon className="h-4 w-auto m-1" />;
							break;
						}

						case index > currentStepIndex: {
							icon = (
								<NextStepIcon
									className={cn(
										'h-2.5 w-auto m-1 ml-2',
										scenario === 'create' && 'text-ca-gray-400',
										scenario === 'update' && 'text-ca-purple',
									)}
								/>
							);
							break;
						}

						default:
						// why?
					}

					return (
						<div
							key={step.id}
							className={cn(
								'flex items-center py-2 text-sm text-ca-gray-500',
								index === currentStepIndex && 'text-ca-purple',
								step.className,
							)}
						>
							<span className="flex items-center justify-center h-3">
								{icon}
							</span>
							<span className="ml-2">{step.name}</span>
						</div>
					);
				})}
			</div>
			<form
				onSubmit={doFormSubmission(handleSubmit)}
				id="strategy-details"
				className="flex flex-col min-w-[640px] w-max min-h-[320px] max-h-[100vh] overflow-hidden pl-6"
			>
				{currentStep.title}
				<div className="flex-grow flex flex-col overflow-auto">
					{stepsMerged.map((item) => {
						if (item.id !== currentStepId) {
							return null;
						}
						return <div key={item.id}>{forms[item.id]}</div>;
					})}
				</div>
				<div className="flex">
					<Button variant="link" className="font-medium" onClick={prevStep}>
						{currentStep.backButton}
					</Button>
					<Button
						className="ml-auto flex flex-row items-center gap-3 whitespace-nowrap"
						variant="primary"
						form="strategy-details"
						type="submit"
						disabled={isSaving}
					>
						{isSaving ? 'Saving...' : currentStep.forwardButton}
					</Button>
				</div>
			</form>
		</div>
	);

	if (isFetching) {
		modalContent = (
			<div className="flex flex-col w-[600px] h-[280px] max-w-full max-h-full items-center justify-center">
				<CircularProgress />
			</div>
		);
	}

	return (
		<Modal.Root className="w-full max-w-7xl rounded-2xl overflow-hidden">
			<Modal.Content className="relative p-2.5 bg-ca-gray-100">
				<span className="absolute flex right-5 top-5">
					<IconButton
						className="w-4 h-auto text-ca-gray-500"
						tooltip="Close"
						icon={CrossIcon}
						onClick={() => {
							close();
						}}
					/>
				</span>
				{modalContent}
			</Modal.Content>
		</Modal.Root>
	);
};
