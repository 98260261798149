import api from 'api/api';
import useChannelStore from 'hooks/channels/useChannelStore';
import { useMutation } from 'react-query';
import constructChannelQueryKey from 'utils/channelUtils';
import { paths } from '../../../types/backend-api';

type CreateMarkdownTransfer =
	paths['/v2/recommendations/{strategy_id}/markdown_transfer']['post'];
export const CREATE_MARKDOWN_TRANSFER = ({
	strategyId,
	filterSearchParams,
	payload,
}: {
	strategyId: CreateMarkdownTransfer['parameters']['path']['strategy_id'];
	filterSearchParams: URLSearchParams;
	payload: CreateMarkdownTransfer['requestBody']['content']['application/json'];
}) => {
	return api
		.post(`api/v2/recommendations/${strategyId}/markdown_transfer`, {
			searchParams: filterSearchParams,
			json: payload,
		})
		.json();
};

const MARKDOWN_TRANSFER_MUTATION_KEY = ['markdown_transfer'] as const;

const useMarkdownTransferMutation = () => {
	const { activeChannel } = useChannelStore();
	const mutationKey = constructChannelQueryKey(
		activeChannel,
		MARKDOWN_TRANSFER_MUTATION_KEY,
	);

	return useMutation({
		mutationKey,
		mutationFn: CREATE_MARKDOWN_TRANSFER,
	});
};

export default useMarkdownTransferMutation;
