import { useJWTStore } from 'crunch-components';
import ky from 'ky';
import useChannelStore from '../hooks/channels/useChannelStore';

const api = ky.extend({
	prefixUrl: window._ENV_.REACT_APP_STRATOS_API,
	timeout: window._ENV_.REACT_APP_API_TIMEOUT || 30000,
	hooks: {
		beforeRequest: [
			(request) => {
				const token = useJWTStore.getState()?.jwt?.access_token;
				const activeChannel = useChannelStore.getState()?.activeChannel;

				if (activeChannel) {
					request.headers.set('channel', activeChannel);
				}

				if (token) {
					request.headers.set('Authorization', `Bearer ${token}`);
				}

				request.headers.set(
					'org',
					window._ENV_.REACT_APP_AUTH0_ORGANIZATION_ID,
				);
			},
		],
		afterResponse: [
			(_request, _options, response) => {
				// if no jwt is found, log the user out
				if (response.status === 401) {
					useJWTStore.setState({ jwt: null });
				}
			},
		],
	},
});

export default api;
