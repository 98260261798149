import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Minus = ({ className }) => (_jsx("svg", { width: "10", height: "2", viewBox: "0 0 10 2", fill: "none", className: className, xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M1 1H9", stroke: "#7000FF", strokeWidth: 1.2, strokeLinecap: "round" }) }));
Minus.propTypes = {
    className: PropTypes.string,
};
Minus.defaultProps = {
    className: '',
};
export default Minus;
