import useChannelQuery from 'hooks/channels/useChannelQuery';
import { useMemo } from 'react';

import filterMapToSearchParams from 'components/Filter/filterUtils';
import { FilterMap } from 'components/Filter/FullFilterMenu.types';
import { useModal } from 'crunch-components';
import useMarkdownTransferMutation from 'hooks/mutations/useMarkdownTransferMutation';
import { useMarkdownBucketsQuery } from 'hooks/queries/useMarkdownBucketsQuery';
import useStrategyStore from 'hooks/useStrategyStore';
import { GET_RECOMMENDATIONS_FILTERS } from '../../../api/recommendations';
import MarkdownTransferModal from '../components/MarkdownTransferModal';

interface Result {
	isPossible: boolean;
	transferInProgress: boolean;
	openModal: (...args: any[]) => any;
	closeModal: () => any;
}

const useMarkdownTransferModal = (
	filters: FilterMap,
	cumulRef: React.RefObject<any>,
): Result => {
	const { activeStrategy } = useStrategyStore();
	// This is notably the same query (with the same queryKey) as executed elsewhere.
	const filtersQuery = useChannelQuery(
		['recommendations-filters', activeStrategy],
		() => GET_RECOMMENDATIONS_FILTERS(activeStrategy),
		{ staleTime: 1000 * 60 * 5 },
	);

	if (activeStrategy === null) {
		throw new Error('Please create a strategy first');
	}

	useMarkdownBucketsQuery({
		strategy_id: activeStrategy,
		filters: filterMapToSearchParams(filters),
	});
	const { open: openModal, close: closeModal } = useModal();

	const { mutate: transferFunction, isLoading: transferInProgress } =
		useMarkdownTransferMutation();

	const isPossible = useMemo(() => {
		if (filtersQuery.isSuccess && Array.isArray(filtersQuery?.data)) {
			const toFind = ['markdown_type', 'overwrite_markdown'];
			(filtersQuery.data ?? []).forEach((filter: any) => {
				const id = toFind.findIndex((filterId) => filterId === filter.id);
				if (id !== -1) {
					delete toFind[id];
				}
			});
			return true;
		}
		return true;
	}, [filtersQuery.isSuccess]);

	return {
		isPossible,
		transferInProgress,
		openModal: isPossible
			? () =>
					openModal({
						modalNode: (
							<MarkdownTransferModal
								transferMarkdownFn={transferFunction}
								activeFilters={filters}
								cumulRef={cumulRef}
							/>
						),
					})
			: () => {},
		closeModal: () => {
			if (closeModal !== undefined) {
				closeModal();
			}
		},
	};
};

export default useMarkdownTransferModal;
