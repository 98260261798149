import { Page } from 'crunch-components';
import UploadsHeader from '../components/UploadsHeader';
import UploadsTable from '../components/UploadsTable';

export default function UploadsView() {
	return (
		<Page className="flex flex-col gap-6">
			<UploadsHeader />
			<UploadsTable />
		</Page>
	);
}
