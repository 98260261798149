import { useLocation } from 'react-router-dom';

import ChannelSelector from 'components/ChannelSelector/ChannelSelector';
import HeaderNav from 'components/HeaderNav/HeaderNav';
import StrategySelector from 'components/StrategySelector/StrategySelector';
import { Greetings, Title } from 'crunch-components';
import { entriesFromObject } from 'crunch-utils';
import { useAllLatestRunStatusesQuery } from 'hooks/queries/useAllLatestRunStatusesQuery';
import useStrategyStore from 'hooks/useStrategyStore';
import AlgorithmStatus from 'pages/strategy/components/AlgorithmStatus/AlgorithmStatus';
import MultiAlgorithmStatus from 'pages/strategy/components/AlgorithmStatus/MultiAlgorithmStatus';
import {
	AllStrategiesStatusDataType,
	StatusInfo,
	Strategy,
} from 'pages/strategy/types/strategies';
import { Helmet } from 'react-helmet-async';
import RecommendationsFilter from './components/RecommendationsFilter';

type RecommendationsHeaderProps = {
	disableStrategySpecific?: boolean;
};

export default function RecommendationsHeader({
	disableStrategySpecific,
}: RecommendationsHeaderProps) {
	const { strategies } = useStrategyStore();
	const { data: allStatuses } = useAllLatestRunStatusesQuery();

	const strategyStatuses = allStatuses
		? constructStrategyStatuses(strategies, allStatuses)
		: undefined;

	return (
		<header>
			<Helmet title="Crunch Platform | Recommendations" />
			<div className="flex justify-between">
				<div className="flex flex-col gap-2">
					<Title>Recommendations</Title>
					<Greetings />
				</div>
				<div className="flex flex-col gap-2">
					<ChannelSelector />
					<StrategySelector disabled={disableStrategySpecific} />
				</div>
			</div>
			<div className="mt-6">
				<RecommendationsNav
					strategySpecific={!disableStrategySpecific}
					strategyStatuses={strategyStatuses}
				/>
			</div>
			<div className="mt-6">
				<RecommendationsFilter
					disableStrategySpecific={disableStrategySpecific}
					strategyStatuses={allStatuses}
				/>
			</div>
		</header>
	);
}

type RecommendationsNavProps = {
	strategySpecific?: boolean;
	strategyStatuses?: StatusInfo[];
};

const NAV_ITEMS = [
	{
		id: 'comparison',
		label: 'Strategy Comparison',
		to: '/recommendations/strategy-comparison',
	},
	{
		id: 'strategy_details',
		label: 'Strategy Details',
		to: '/recommendations/strategy-details',
	},
	// Business rule deep dive disabled for now
	// {
	// 	id: 'business_rule_insights',
	// 	label: 'Business Rule Impact',
	// 	to: '/recommendations/business-rule-insights',
	// },
	{
		id: 'product_details',
		label: 'Product Details',
		to: '/recommendations/product-details',
	},
];

const GLOBAL_NAV_ITEMS = [
	{
		id: 'global_retrospective',
		label: 'Retrospective',
		to: '/recommendations/global/retrospective',
	},
	{
		id: 'global_summary',
		label: 'Summary',
		to: '/recommendations/global/summary',
	},
];

const RecommendationsNav = (props: RecommendationsNavProps) => {
	const { pathname } = useLocation();
	const { activeStrategy: activeStrategyId } = useStrategyStore();

	const activeStrategyStatus = props.strategyStatuses?.find(
		(statusInfo) => statusInfo.strategyId === activeStrategyId,
	);

	const navItems = pathname.startsWith('/recommendations/global')
		? GLOBAL_NAV_ITEMS
		: NAV_ITEMS;

	const statusLine = props.strategySpecific ? (
		<AlgorithmStatus
			strategyStatus={activeStrategyStatus}
			className="absolute right-0 bottom-0 mb-2 hidden lg:flex"
		/>
	) : (
		<MultiAlgorithmStatus
			statuses={props.strategyStatuses}
			className="absolute right-0 bottom-0 mb-2 hidden lg:flex"
		/>
	);

	return (
		<div className="relative">
			<HeaderNav items={navItems} />
			{statusLine}
		</div>
	);
};

function constructStrategyStatuses(
	strategies: Strategy[],
	statuses: AllStrategiesStatusDataType,
): StatusInfo[] {
	const entries = entriesFromObject(statuses);
	const strategyStatuses = entries.reduce<StatusInfo[]>((acc, status) => {
		const matchedStrategy = strategies.find(
			(strategy) => strategy.id === status[0],
		);

		if (!matchedStrategy || status[1] === null) {
			return acc;
		}

		return [
			...acc,
			{
				strategyName: matchedStrategy.name,
				strategyId: matchedStrategy.id,
				status: status[1].simplified_status,
				outdatedReason: status[1].outdated_reason,
				updatedAt: status[1].updated_at,
			},
		];
	}, []);

	return strategyStatuses;
}
