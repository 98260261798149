import { GET_ACTUALS_FILTERS } from 'api/reports';
import FullFilterMenu from 'components/Filter/FullFilterMenu';
import { FilterMap } from 'components/Filter/FullFilterMenu.types';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import { Dispatch, SetStateAction } from 'react';
import { components } from '../../../../types/backend-api';

type ActualsFilterProps = {
	filters: FilterMap;
	setFilters: Dispatch<SetStateAction<FilterMap>>;
};

type UIFilter = components['schemas']['UIFilterDefinition'];

const ActualsFilter = ({ filters, setFilters }: ActualsFilterProps) => {
	const filterQuery = () =>
		useChannelQuery<UIFilter[], unknown, UIFilter[]>(
			['actuals-filters'],
			() => GET_ACTUALS_FILTERS(),
			{
				staleTime: 5 * 60 * 1000,
			},
		);
	if (!filterQuery) return null;
	return (
		<FullFilterMenu
			filterQuery={filterQuery}
			filters={filters}
			setFilters={setFilters}
		/>
	);
};

export default ActualsFilter;
