import { Title } from 'crunch-components';

const MarkdownTransferSelection = ({ children }) => {
	return (
		<section>
			<Title type="section" className="mb-4">
				What do you want to transfer?
			</Title>
			{children}
		</section>
	);
};

export default MarkdownTransferSelection;
