import { jsx as _jsx } from "react/jsx-runtime";
import cn from '../../utils/cn';
import Text from '../Text/Text';
const Title = (props) => (_jsx(Text, { size: "text-base", className: "font-bold mb-8", children: props.children }));
const Content = ({ className = '', children, unstyled = false }) => (_jsx("div", { className: cn(!unstyled && 'p-10', className), children: children }));
const Actions = ({ children, className = '', unstyled = false, }) => {
    return (_jsx("div", { className: cn(!unstyled && 'py-5 px-10 rounded-b-[inherit] bg-ca-ghost-white', className), children: children }));
};
const Root = ({ children, className = '', unstyled = false, }) => {
    return (_jsx("div", { className: cn(!unstyled && 'max-w-lg bg-white rounded-lg shadow-2xl', className), children: children }));
};
export default { Root, Title, Content, Actions };
