import { Auth0Provider } from '@auth0/auth0-react';
import StratosQueryClientProvider from 'contexts/StratosQueryClientProvider';
import { JWTProvider, ModalProvider } from 'crunch-components';
import { HelmetProvider } from 'react-helmet-async';
import { authorizationParams } from 'utils/authUtils';

export default function BaseProviders(props: { children: React.ReactNode }) {
	return (
		<Auth0Provider
			domain={window._ENV_.REACT_APP_AUTH0_DOMAIN}
			clientId={window._ENV_.REACT_APP_AUTH0_CLIENT_ID}
			cacheLocation="localstorage"
			/* useRefreshTokens */
			authorizationParams={authorizationParams}
		>
			<JWTProvider
				auth0Audience={authorizationParams.audience}
				auth0Scope={authorizationParams.scope}
			>
				<StratosQueryClientProvider>
					<HelmetProvider>
						<ModalProvider>{props.children}</ModalProvider>
					</HelmetProvider>
				</StratosQueryClientProvider>
			</JWTProvider>
		</Auth0Provider>
	);
}
