import {
	AllLatestUploadsDataType,
	UploadersDataType,
} from 'pages/uploads/types/uploads';
import api from './api';

export const LIST_UPLOADERS = (): Promise<UploadersDataType> => {
	return api.get(`api/v2/uploaders`).json();
};

export const LIST_ALL_LATEST_UPLOADS =
	(): Promise<AllLatestUploadsDataType> => {
		return api.get(`api/v2/uploads/all-latest`).json();
	};
