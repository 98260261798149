import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import cn from '../../utils/cn';
import Button from '../Button/Button';
import TrashIcon from '../Icons/Trash';
import Tooltip from '../Tooltip/Tooltip';
const DeleteButton = React.forwardRef(({ onClick, className, tooltip, iconClassName, ...rest }, ref) => {
    const [isActive, setActive] = React.useState(false);
    const handleClick = () => {
        if (!isActive) {
            setActive(true);
            return;
        }
        onClick?.();
    };
    const handleBlur = () => {
        if (isActive) {
            setActive(false);
        }
    };
    return (_jsx(Tooltip, { content: tooltip, children: _jsxs(Button, { ref: ref, title: "Delete", variant: isActive ? 'danger' : 'unstyled', size: "small", className: cn('flex flex-row items-center gap-3 whitespace-nowrap !transition-all w-9 h-8 justify-center overflow-hidden', isActive && 'w-30', className), onClick: handleClick, onBlur: handleBlur, ...rest, children: [isActive ? (_jsx("span", { className: "mt-0.25 text-white text-xxs transition-all", children: "Are you sure?" })) : null, _jsx(TrashIcon, { className: cn('h-3.5 w-auto transition-all', iconClassName, isActive ? 'text-white' : 'text-ca-gray-500') })] }) }));
});
export default DeleteButton;
