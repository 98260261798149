import { AuthGuard, SmartHomeRedirect } from 'crunch-components';
import PageWithSidebar from 'layouts/PageWithSidebar';
import Stratos404 from 'pages/404/Stratos404';
import actualsRouting from 'pages/actuals/ActualsRouting';
import globalRouting from 'pages/global/GlobalRouting';
import LoginView from 'pages/login/views/Login';
import recommendationsRouting from 'pages/recommendations/RecommendationsRouting';
import strategyRouting from 'pages/strategy/StrategyRouting';
import uploadsRouting from 'pages/uploads/UploadsRouting';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
} from 'react-router-dom';
import { DEFAULT_PATHS } from 'utils/routerUtils';
import PrivateProviders from './components/PrivateProviders';

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/">
			<Route index element={<SmartHomeRedirect homePaths={DEFAULT_PATHS} />} />
			<Route
				path="*"
				element={<SmartHomeRedirect homePaths={DEFAULT_PATHS} />}
			/>
			<Route path="/404" element={<Stratos404 />} />
			<Route
				element={
					<AuthGuard
						accessibleBy="public-only"
						privateHomePath={DEFAULT_PATHS['private']}
					/>
				}
			>
				<Route path="login" element={<LoginView />} />
			</Route>
			<Route
				element={
					<AuthGuard
						accessibleBy="private-only"
						publicHomePath={DEFAULT_PATHS['public']}
					/>
				}
			>
				<Route element={<PrivateProviders />}>
					<Route element={<PageWithSidebar />}>
						{strategyRouting}
						{actualsRouting}
						{recommendationsRouting}
						{globalRouting}
						{uploadsRouting}
					</Route>
				</Route>
			</Route>
		</Route>,
	),
);

export default router;
