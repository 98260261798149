/**
 * Calculate the area under the curve (AUC), based on
 * the minimum and maximum residual value
 * and the sellThroughTarget which determines the breakpoint of the curve.
 * @param  {Number} minValueRv The minimum residual value
 * @param  {Number} maxValueRv The maximum residual value
 * @param  {Number} sellThroughTarget The sellThroughTarget which determines the breakpoint = 100 - sellThroughTarget.
 * @return {Number}      The area under the curve (auc), a number that can
 */
const calculateAuc = (
	maxValueRv: number,
	minValueRv?: number,
	sellThroughTarget = 100,
) => {
	// Even in the clearance scenario with negative max value, we should use the positive value to calculate the auc
	const trueMaxRv = Math.abs(maxValueRv);
	// if minValueRv is null, we want to use the trueMaxRv, which will make partThree equal to zero
	const trueMinRv = minValueRv ?? trueMaxRv;
	// boolean indicating that min and maxValueRv are different
	const differentMinMax = trueMinRv - maxValueRv !== 0;
	// X value of the first square, until breakpoint (partOne)
	const partOneAucX = 100 - sellThroughTarget;
	// Y value of the first square, until breakpoint (partOne)
	const partOneAucY = trueMaxRv;
	// X value of the potential square under the triangle if minRv is greater than or equal to zero.
	const parttwoAucX = trueMinRv >= 0 ? sellThroughTarget : 0;
	// Y value of the potential square under the triangle if minRv is greater than or equal to zero.
	const parttwoAucY = Math.max(trueMinRv, 0);
	// X value of the potential triangle, intersection with the x axis if minValueRv is a negative number (else equal to sellThroughTarget)
	const partThreeAucX =
		differentMinMax && trueMinRv < 0
			? sellThroughTarget -
				(trueMinRv * sellThroughTarget) / (trueMinRv - maxValueRv)
			: sellThroughTarget;
	// Y value of the potential triangle, equal to maxValueRv - minValueRv unless minValueRv is a negative number or equal to maxValueRv
	const partThreeAucY =
		differentMinMax && trueMinRv < 0 ? maxValueRv : maxValueRv - trueMinRv;
	/**
	 * calculate the area under the curve, which is the sum of
	 *  the area from the first square, until breakpoint (partOne)
	 *  the area from the potential square under the triagle,if minRv is positive (partTwo)
	 *  the area from the potential triagle starting from the breakpoint until it cuts the x axis (partThree)
	 */
	const auc =
		partOneAucX * partOneAucY +
		parttwoAucX * parttwoAucY +
		(partThreeAucX * partThreeAucY) / 2;
	return auc;
};

export default calculateAuc;
