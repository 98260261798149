import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import relativeTime from "dayjs/plugin/relativeTime";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
export const FORMAT_READABLE = "D MMM YYYY";
export const FORMAT_BASE = "YYYY-MM-DD";
export const weekNumber = (date) => {
    return dayjs(date).week();
};
export const getWeekPeriod = (date) => {
    const inst = dayjs(date);
    return [inst.startOf("isoWeek"), inst.endOf("isoWeek")];
};
export const today = (date) => {
    return dayjs(date);
};
export const isWeekAfter = ({ date, endDate, }) => {
    const dateWeek = date.startOf("isoWeek");
    const endDateWeek = endDate.startOf("isoWeek");
    return dateWeek.isAfter(endDateWeek);
};
export const getSelectableDates = ({ startPeriodDate, endPeriodDate, selectedDates, allowDate, }) => {
    const firstDay = startPeriodDate
        ? startPeriodDate.startOf("isoWeek")
        : undefined;
    const lastDay = endPeriodDate ? endPeriodDate.endOf("isoWeek") : undefined;
    const disabledDates = selectedDates
        ? selectedDates
            .filter(item => (allowDate ? !item.isSame(allowDate) : true))
            .map(item => {
            const startOfWeek = item.startOf("isoWeek");
            const endOfWeek = item.endOf("isoWeek");
            return [startOfWeek, endOfWeek];
        })
        : undefined;
    return { firstDay, lastDay, disabledDates };
};
export const getMondayThatWeek = (date) => {
    return date.startOf("isoWeek");
};
