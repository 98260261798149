import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import StackedGrid from '../StackedGrid/StackedGrid';
import cn from '../../utils/cn';
const colorVariants = {
    purple: 'bg-ca-purple',
    red: 'bg-red-600',
};
const CornerPinger = (props) => {
    return (_jsxs("div", { className: "relative", children: [props.children, !props.hidden === true && (_jsxs(StackedGrid, { className: "absolute top-0 right-0 translate-x-[35%] -translate-y-[35%] place-items-center", children: [_jsx("div", { className: cn('w-3 h-3 rounded-full border-2', colorVariants[props.pingColor ?? 'purple'], props.fakeCutoutBorderClassName ?? 'border-ca-ghost-white') }), _jsx("div", { className: cn('w-3 h-3 rounded-full animate-ping opacity-75', colorVariants[props.pingColor ?? 'purple']) })] }))] }));
};
export default CornerPinger;
