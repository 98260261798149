// Gets the value at path of object.
// If the resolved value is undefined,
// the defaultValue is returned in its place.
// source: https://gist.github.com/harish2704/d0ee530e6ee75bad6fd30c98e5ad9dab#gistcomment-3148552
const get = (object, path, defaultValue) => {
    const pathArray = Array.isArray(path)
        ? path
        : path.split('.').filter((key) => key);
    const pathArrayFlat = pathArray.flatMap((part) => typeof part === 'string' ? part.split('.') : part);
    const checkValue = pathArrayFlat.reduce((obj, key) => obj && obj[key], object);
    return checkValue === undefined ? defaultValue : checkValue;
};
export default get;
