import api from './api';

export const GET_CUMULIO_RETROSPECTIVE_SSO = () => {
	return api.get(`api/v2/reports/cumulio/retrospective/sso`).json();
};

export const GET_CUMULIO_STRATEGY_COMPARISON_SSO = (searchParams) => {
	return api
		.get('api/v2/reports/cumulio/recommendations/comparison/sso', {
			searchParams,
		})
		.json();
};

export const GET_CUMULIO_STRATEGY_DETAILS_SSO = (
	activeStrategy,
	searchParams,
) => {
	return api
		.get(
			`api/v2/reports/cumulio/recommendations/${activeStrategy}/overview/sso`,
			{ searchParams },
		)
		.json();
};

export const GET_CUMULIO_BUSINESS_RULE_INSIGHTS_SSO = (
	activeStrategy,
	searchParams
) => {
	return api
		.get(
			`api/v2/reports/cumulio/recommendations/${activeStrategy}/business-rule-insights/sso`,
			{ searchParams }
		)
		.json();
};

export const GET_CUMULIO_RECOMMENDATIONS_GLOBAL_RETROSPECTIVE_SSO = (week) => {
	if (!week) {
		return null;
	}

	return api
		.get(`api/v2/reports/cumulio/recommendations/global/retrospective/sso`)
		.json();
};

export const GET_CUMULIO_RECOMMENDATIONS_GLOBAL_SUMMARY_SSO = () => {
	return api
		.get('api/v2/reports/cumulio/recommendations/global/overview/sso')
		.json();
};
export const GET_CUMULIO_RECOMMENDATIONS_PRODUCT_SSO = (
	activeStrategy,
	productId,
) => {
	return api
		.get(
			`api/v2/reports/cumulio/recommendations/${activeStrategy}/${productId}/sso`,
		)
		.json();
};

export const GET_CUMULIO_ACTUALS_SALES_SSO = (searchParams) => {
	return api
		.get(`api/v2/reports/cumulio/actuals/sales/sso`, { searchParams })
		.json();
};

export const GET_CUMULIO_ACTUALS_INVENTORY_SSO = (searchParams) => {
	return api
		.get(`api/v2/reports/cumulio/actuals/inventory/sso`, { searchParams })
		.json();
};

export const GET_CUMULIO_SUPPORT_VALIDATION_SSO = () => {
	return api.get(`api/v2/reports/cumulio/support/validation/sso`).json();
};

export const GET_CUMULIO_ACTUALS_YOYCOMPARISON_SSO = (searchParams) => {
	return api
		.get(`api/v2/reports/cumulio/actuals/yoycomparison/sso`, { searchParams })
		.json();
};

export const GET_CUMULIO_ACTUALS_PRODUCT_SSO = (searchParams) => {
	return api
		.get(`api/v2/reports/cumulio/actuals/product/sso`, { searchParams })
		.json();
};

export const GET_CUMULIO_GLOBAL_SSO = () => {
	return api.get(`api/v2/reports/cumulio/global/sso`).json();
};

export const GET_ACTUALS_FILTERS = () => {
	return api.get(`api/v2/reports/cumulio/actuals/filters`).json();
};
