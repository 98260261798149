import create from 'zustand';
import { id } from 'crunch-utils';
const useToast = create((set, get) => ({
    toasts: [],
    show: (message, options) => {
        const defaultOptions = { type: 'success', lifeSpan: 5000 };
        const { type, lifeSpan } = { ...defaultOptions, ...options };
        const toast = { id: id(), message, type, visible: true };
        set(({ toasts }) => ({
            toasts: [...toasts, toast],
        }));
        if (lifeSpan !== 'indefinite' && lifeSpan >= 0) {
            setTimeout(() => {
                get().close(toast.id);
            }, lifeSpan);
        }
        return toast.id;
    },
    close: (toastId) => {
        set(({ toasts }) => ({
            toasts: toasts.map((toast) => {
                if (toast.id === toastId) {
                    return { ...toast, visible: false };
                }
                return toast;
            }),
        }));
    },
    // TODO ward:check if toasts still work include the .cleanup
    cleanup: () => {
        set(({ toasts }) => {
            return { toasts: toasts.filter((toast) => toast.visible) };
        });
    },
}));
export default useToast;
