import { paths } from '../../types/backend-api';
import api from './api';

export type RecommendationsDataType =
	paths['/v2/recommendations/schema']['get']['responses']['200']['content']['application/json'];

export const GET_RECOMMENDATIONS_SCHEMA = () => {
	return api
		.get(`api/v2/recommendations/schema`)
		.json<RecommendationsDataType>();
};
