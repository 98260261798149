import { Navigate, Route } from 'react-router-dom';
import ConditionalFeatureRoute from 'routing/components/ConditionalFeatureRoute';
import EnsureActiveStrategy from './views/EsnureActiveStrategy';
import ProductDetails from './views/ProductDetails';
import StrategyComparison from './views/StrategyComparison';
import StrategyDetails from './views/StrategyDetails';

const recommendationsRouting = (
	<Route
		path="recommendations"
		element={
			<ConditionalFeatureRoute
				feature="recommendations"
				renderMethod="outlet"
			/>
		}
	>
		<Route index element={<Navigate to="strategy-comparison" />} />
		<Route element={<EnsureActiveStrategy />}>
			<Route path="strategy-comparison" element={<StrategyComparison />} />
			<Route path="strategy-details" element={<StrategyDetails />} />
			<Route path="product-details" element={<ProductDetails />} />
		</Route>
	</Route>
);

export default recommendationsRouting;
