import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const CompletedStep = ({ className }) => {
    return (_jsx("svg", { className: className, xmlns: "http://www.w3.org/2000/svg", width: "17", height: "17", fill: "none", viewBox: "0 0 17 17", children: _jsx("path", { fill: "#6111C7", d: "M8 .5a8 8 0 108 8 8.008 8.008 0 00-8-8zm3.512 6.59l-4.307 4.307a.616.616 0 01-.871 0L4.488 9.55a.616.616 0 01.87-.871l1.411 1.412 3.873-3.874a.616.616 0 01.87.871z" }) }));
};
CompletedStep.propTypes = {
    className: PropTypes.string,
};
CompletedStep.defaultProps = {
    className: '',
};
export default CompletedStep;
