export const FEATURES_MAP = {
	global: {
		envKey: 'REACT_APP_GLOBAL_CHANNEL_ENABLED',
	},
	recommendations: {
		envKey: 'REACT_APP_RECOMMENDATIONS_ENABLED',
	},
	actuals: {
		envKey: 'REACT_APP_ACTUALS_ENABLED',
	},
	strategy: {
		envKey: 'REACT_APP_STRATEGY_ENABLED',
	},
	uploader: {
		envKey: 'REACT_APP_FILE_UPLOADER_ENABLED',
	},
} as const; /* TODO: uncomment after typescript upgrade to 4.9 or higher. // satisfies Record<string, { envKey: keyof AppEnvironment }> */

export function isFeatureEnabled(
	feature: keyof typeof FEATURES_MAP,
	currentChannel?: string,
): boolean {
	const envValue = window._ENV_[FEATURES_MAP[feature].envKey];
	if (typeof envValue === 'boolean') {
		return envValue;
	}

	if (currentChannel === undefined) {
		return false;
	}

	const asArray: [string] =
		typeof envValue === 'string'
			? JSON.parse(envValue.replace("'", ''))
			: envValue;

	return !!asArray.find((enabledChannel) => enabledChannel === currentChannel);
}
