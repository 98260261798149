import StratosSidebar from 'components/StratosSidebar/StratosSidebar';
import { Page } from 'crunch-components';
import { Outlet } from 'react-router-dom';

export default function PageWithSidebar() {
	return (
		<div className="flex min-h-screen">
			<StratosSidebar />
			<Page>
				<Outlet />
			</Page>
		</div>
	);
}
