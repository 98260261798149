import { jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Text from '../Text/Text';
const Greeting = () => {
    const { user } = useAuth0();
    const [greeting, setGreeting] = useState('');
    useEffect(() => {
        const getGreeting = () => {
            const hour = dayjs().hour();
            if (hour < 12) {
                setGreeting('Good morning'); // 00:00 - 11:59
            }
            else if (hour < 18) {
                setGreeting('Good afternoon'); // 12:00 - 17:59
            }
            else {
                setGreeting('Good evening'); // 17:59 - 23:59
            }
        };
        getGreeting();
        // re-check the greeting every 60 seconds
        const interval = setInterval(getGreeting, 60 * 1000);
        return () => clearInterval(interval);
    }, []);
    if (!greeting)
        return null;
    return (_jsxs(Text, { className: "leading-none", size: "text-lg", type: "secondary", children: [greeting, user?.given_name ? `, ${user.given_name}` : '', ". \uD83D\uDC4B"] }));
};
export default Greeting;
