/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import tailwindColors from 'tailwindcss/colors';

import {
	cn,
	LoadingSpinner,
	TriangleDownIcon,
	TrianglesDownIcon,
} from 'crunch-components';
import { range } from 'crunch-utils';
import { Prettify } from '../types/helpers';
import { SliderOptionsType } from '../types/objectives';
import { PresetSliderValue } from '../types/strategies';
import { findMatchedScenarioIndex, getScenarioHexColor } from './utils';

type ReadonlyProps = {
	className?: string;
	options: SliderOptionsType;
	/** readonly slider displays several values but can't be adjusted */
	mode: 'readonly';
	value: PresetSliderValue[];
};

type RegularProps = {
	className?: string;
	options: SliderOptionsType;
	mode?: Exclude<string, 'readonly'>;
	onChange: (
		values: [SliderOptionsType[number], number, PresetSliderValue],
	) => void;
	value: PresetSliderValue;
};

type ObjectivePresetSliderProps = Prettify<RegularProps | ReadonlyProps>;

function isReadonlyProps(props: unknown): props is ReadonlyProps {
	if (
		props &&
		typeof props === 'object' &&
		'mode' in props &&
		(props as any).mode === 'readonly'
	) {
		return true;
	}
	return false;
}

type SliderBarProps = {
	/** Css value like rgb(...), #ff00ff etc */
	backgroundColorCss: string;
	/** Css value like rgb(...), #ff00ff etc */
	foregroundColorCss: string;
	/** Between 0 and 1 */
	progress: number;
	className?: string;
};

const SliderBar = (props: SliderBarProps) => {
	return (
		<div
			className={cn(props.className ?? '', 'relative rounded-full h-1 w-full')}
			style={{ backgroundColor: props.backgroundColorCss }}
		>
			<div
				className="absolute rounded-full h-1 left-0 top-0 bottom-0 transition-all"
				style={{
					backgroundColor: props.foregroundColorCss,
					right: `${100 - props.progress * 100}%`,
				}}
			/>
		</div>
	);
};

type SliderLabelProps = {
	/** Between 0 and 1 */
	progress: number;
	content: React.ReactNode;
};

const SliderLabel = ({ progress: position, content }: SliderLabelProps) => {
	return (
		<div
			className="absolute z-0 transition-all translate-x-1/2 pointer-events-none select-none"
			style={{
				left: `calc(${position * 100}% - ${(position - 0.6) * 6}px)`,
			}}
		>
			<span className="absolute -top-6 left-1/2 -translate-x-1/2 text-zinc-800 pb-0.5 rounded-md transition-all">
				{content}
			</span>
		</div>
	);
};

const NOTCH_SIZES = {
	sm: {
		tailwindClass: 'w-2 h-2 border',
		px: 8,
	},
	lg: {
		tailwindClass: 'w-2 h-2 border-2',
		px: 6,
	},
} as const;

type SliderNotchProps = {
	/** Between 0 and 1 */
	progress: number;
	size: keyof typeof NOTCH_SIZES;
	colorCss?: string;
	borderColorCss?: string;
};

const SliderNotch = (props: SliderNotchProps) => {
	return (
		<div
			className={cn(
				NOTCH_SIZES[props.size].tailwindClass,
				'absolute rounded-full transition-all',
			)}
			style={{
				borderColor: props.borderColorCss,
				backgroundColor: props.colorCss,
				left: `${props.progress * 100}%`,
				transform: `translate(${
					-props.progress * NOTCH_SIZES[props.size].px
				}px, -50%)`,
			}}
		/>
	);
};

type SliderNotchesProps = {
	step: number;
	min: number;
	max: number;
	value: number;
	activeFillColorCss: string;
	activeBorderColorCss: string;
	inactiveFillColorCss: string;
	inactiveBorderColorCss: string;
};

const SliderNotches = (props: SliderNotchesProps) => {
	return (
		<div className="row-start-1 row-span-1 col-start-1 col-span-1 relative">
			{range(props.min, props.max, props.step).map((t) => (
				<SliderNotch
					progress={t}
					size={Math.abs(t - props.value) <= Number.EPSILON ? 'lg' : 'sm'}
					colorCss={
						t - props.value < -Number.EPSILON
							? props.activeFillColorCss
							: props.inactiveFillColorCss
					}
					borderColorCss={
						t - props.value <= Number.EPSILON
							? props.activeBorderColorCss
							: props.inactiveBorderColorCss
					}
					key={`notch-${t}`}
				/>
			))}
		</div>
	);
};

type ReadonlySliderNotchesProps = {
	step: number;
	min: number;
	max: number;
	values: Record<number, string>;
	inactiveFillColorCss: string;
	inactiveBorderColorCss: string;
};

const ReadonlySliderNotches = (props: ReadonlySliderNotchesProps) => {
	return (
		<div className="row-start-1 row-span-1 col-start-1 col-span-1 relative">
			{range(props.min, props.max, props.step).map((t, idx) => (
				<SliderNotch
					progress={t}
					size="sm"
					colorCss={
						props.values[idx] ? props.values[idx] : props.inactiveFillColorCss
					}
					borderColorCss={
						props.values[idx] ? props.values[idx] : props.inactiveBorderColorCss
					}
					key={`notch-${t}`}
				/>
			))}
		</div>
	);
};

const ReadOnlySlider = (props: ReadonlyProps) => {
	const orderedDefaultScenarios = (props.options ?? []).sort(
		(a, b) => a.intensity_level - b.intensity_level,
	);

	const stepSize =
		props.options?.length < 2 ? 1 : 1 / (props.options?.length - 1);

	const transformed = props.value.reduce(
		(acc, sliderValue) => {
			const idx = findMatchedScenarioIndex({
				scenarios: orderedDefaultScenarios,
				sliderValue,
			});
			if (!acc[idx]) {
				acc[idx] = [];
			}
			acc[idx].push(orderedDefaultScenarios[idx]);
			return acc;
		},
		{} as Record<number, SliderOptionsType>,
	);
	const matched = Object.entries(transformed).map(([key, value]) => {
		return {
			matchedIndex: Number(key),
			count: value.length,
		};
	});
	const notches = matched.reduce(
		(acc, item) => {
			const sliderPosition =
				orderedDefaultScenarios.length === 1
					? stepSize
					: item.matchedIndex * stepSize;

			const activeHexColor = getScenarioHexColor(sliderPosition);
			acc[item.matchedIndex] = activeHexColor;
			return acc;
		},
		{} as Record<number, string>,
	);

	return (
		<div className={cn(props.className, 'flex gap-2')}>
			<div
				className={cn(
					'relative grid grid-rows-1 grid-cols-1 items-center flex-grow',
				)}
			>
				<SliderBar
					className="row-start-1 col-start-1"
					progress={0}
					backgroundColorCss={tailwindColors.zinc['200']}
					foregroundColorCss={tailwindColors.zinc['200']}
				/>

				{matched.map((item) => {
					const sliderPosition =
						orderedDefaultScenarios.length === 1
							? stepSize
							: item.matchedIndex * stepSize;

					const activeHexColor = getScenarioHexColor(sliderPosition);

					return (
						<SliderLabel
							key={sliderPosition}
							progress={sliderPosition}
							content={
								item.count > 1 ? (
									<TrianglesDownIcon
										className="h-[1.125rem] -mt-1.5 w-auto"
										style={{ color: activeHexColor }}
									/>
								) : (
									<TriangleDownIcon
										className="h-2 w-auto"
										style={{ color: activeHexColor }}
									/>
								)
							}
						/>
					);
				})}

				<ReadonlySliderNotches
					min={0}
					max={1}
					step={stepSize}
					values={notches}
					inactiveFillColorCss={tailwindColors.zinc['200']}
					inactiveBorderColorCss={tailwindColors.zinc['300']}
				/>
			</div>
		</div>
	);
};

export const ObjectivePresetSlider = (props: ObjectivePresetSliderProps) => {
	if (props.options?.length === 0 || props.value === undefined) {
		return (
			<div>
				Loading...
				<LoadingSpinner variant="lg" />
			</div>
		);
	}

	// TODO
	if (isReadonlyProps(props)) {
		return <ReadOnlySlider {...props} />;
	}

	const orderedDefaultScenarios = (props.options ?? []).sort(
		(a, b) => a.intensity_level - b.intensity_level,
	);

	const stepSize =
		props.options?.length < 2 ? 1 : 1 / (props.options?.length - 1);
	const matchedIndex = findMatchedScenarioIndex({
		scenarios: orderedDefaultScenarios,
		sliderValue: props.value,
	});

	const sliderPosition =
		orderedDefaultScenarios.length === 1 ? stepSize : matchedIndex * stepSize;

	const activeHexColor = getScenarioHexColor(
		orderedDefaultScenarios.length > 1
			? matchedIndex / (orderedDefaultScenarios.length - 1)
			: 1,
	);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		const scenarioIndex =
			orderedDefaultScenarios.length === 1
				? 0
				: Math.round((parseFloat(value) * 1) / stepSize);

		const scenario = orderedDefaultScenarios[scenarioIndex];
		props.onChange([scenario, scenario.intensity_level, props.value]);
	};

	return (
		<div className={cn(props.className, 'flex gap-2')}>
			<div
				className={cn(
					'relative grid grid-rows-1 grid-cols-1 items-center flex-grow',
				)}
			>
				<SliderBar
					className="row-start-1 col-start-1"
					progress={sliderPosition}
					backgroundColorCss={tailwindColors.zinc['200']}
					foregroundColorCss={activeHexColor}
				/>

				<SliderLabel
					progress={sliderPosition}
					content={
						<TriangleDownIcon
							className="h-2 w-auto"
							style={{ color: activeHexColor }}
						/>
					}
				/>

				<SliderNotches
					min={0}
					max={1}
					step={stepSize}
					value={sliderPosition}
					activeFillColorCss={activeHexColor}
					activeBorderColorCss={activeHexColor}
					inactiveFillColorCss={tailwindColors.zinc['200']}
					inactiveBorderColorCss={tailwindColors.zinc['300']}
				/>
				<input
					type="range"
					min={0}
					max={1}
					step={stepSize}
					value={sliderPosition.toString()}
					className="row-start-1 col-start-1 z-10 opacity-0 w-full"
					onChange={handleOnChange}
				/>
			</div>
		</div>
	);
};
