import { Product } from '../shared/models/product';
import api from './api';

export const GET_PRODUCTS = (searchParams: URLSearchParams) => {
	return api.get('api/v2/products', { searchParams }).json();
};

export const GET_PRODUCT = (id: Product['id']) => {
	return api.get(`api/v2/products/${id}`).json();
};

export const GET_PRODUCT_MARKDOWN_RECOMMENDATIONS = (
	strategyId: string,
	id: Product['id'],
) => {
	return api.get(`api/v2/products/recommendations/${strategyId}/${id}`).json();
};
