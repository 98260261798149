import { SeasonPhasesType, SeasonType } from '../types/seasons';
import { Strategy } from '../types/strategies';
import { END_OF_SEASON_PHASE_CONSTANTS, getNewPhaseWeek } from './utils';
import { constructGetWarningsFunction, WarningCreator } from './warningsSystem';

const PHASE_WARNINGS: WarningCreator<{
	phases: SeasonPhasesType;
	expectEndOfSeason: boolean;
}> = {
	noScheduledPhases: {
		trigger: ({ phases, expectEndOfSeason }) => {
			const minimumPhases = expectEndOfSeason ? 2 : 1;
			return phases.afterNow.length < minimumPhases;
		},
		warningMessage:
			'There are no scheduled phases. Please update your season settings.',
	},
	atMaxAllowedPhases: {
		trigger: ({ phases }) => {
			const phasesCountWithoutEndOfSeason = phases.afterNow.filter(
				(phase) => phase.id !== END_OF_SEASON_PHASE_CONSTANTS.id,
			).length;

			return (
				phasesCountWithoutEndOfSeason >=
				window._ENV_.REACT_APP_MAX_SCHEDULED_PHASES
			);
		},
		warningMessage: 'You already have the max amount of phases scheduled',
	},
	noFreeWeekToAddNew: {
		trigger: ({ phases, expectEndOfSeason }) => {
			const endOfSeasonPhase = phases.afterNow.find(
				(phase) => phase.id === END_OF_SEASON_PHASE_CONSTANTS.id,
			);

			if (!expectEndOfSeason && endOfSeasonPhase) {
				throw new Error(
					'[strategy/utils::PHASE_WARNINGS::noFreeWeekToAddNew] End of season phase should NOT be in the provided `phases` argument, but it was.',
				);
			}
			if (expectEndOfSeason && !endOfSeasonPhase) {
				throw new Error(
					"[strategy/utils::PHASE_WARNINGS::noFreeWeekToAddNew] End of season phase should be in the provided `phases` argument, but it was't.",
				);
			}

			if (!expectEndOfSeason && !endOfSeasonPhase) {
				// no end of season found nor expected -> 'noFreeWeekToAddNew'
				return false;
			}

			const firstCandidate = getNewPhaseWeek({ phases: phases.afterNow });

			return !endOfSeasonPhase!.startDate.isAfter(firstCandidate);
		},
		warningMessage:
			'Cannot add new phase. Season is set to end before the new phase could start.',
	},
}; /*
// TODO: uncomment this when you upgrade to react 18 - satisfies will give you auto complete
satisfies WarningCreator<{
	phases: SeasonPhasesType;
	expectEndOfSeason: boolean;
}>; */

const SEASON_WARNINGS: WarningCreator<SeasonType> = {
	seasonEnded: {
		trigger: (season) => {
			return season.isInPast;
		},
		warningMessage:
			'The season end date is in the past. Please update your season settings.',
	},
}; /*
TODO: uncomment
satisfies WarningCreator<SeasonType>; */

// TODO: make max strategies ENV var
const STRATEGIES_WARNINGS: WarningCreator<Strategy[]> = {
	maxStartegies: {
		trigger: (strategies) => {
			return strategies.length >= 10;
		},
		warningMessage: 'You can only have 10 strategies per channel.',
	},
}; /*
// TODO: uncomment
satisfies WarningCreator<StrategyAggregateDataType[]>; */

export const getStrategyWarnings =
	constructGetWarningsFunction(STRATEGIES_WARNINGS);
export const getPhasesWarnings = constructGetWarningsFunction(PHASE_WARNINGS);
export const getSeasonWarnings = constructGetWarningsFunction(SEASON_WARNINGS);
/** How to: define new warnings
 * vvv in code
 * 1. type ToValidate = ... // (or do directly in step 2)
 * 2. const MY_WARNINGS = ... satsfies WarningCreator<ToValidate>
 * 3. export const get...Warnings = constructGetWarningsFunction(MY_WARNINGS);
 *
 * vvv in words
 * 1. Define type for the value you want to check for warnings (or do directly in step 2)
 * 2. define object to define warning validation functions and warning messages (example: PHASE_WARNINGS), which 'satisfies WarningCreator<ToValidate>'
 * 3. Create get...Warnings function using the `getGetWarningsFunction` helper funciton
 * */
