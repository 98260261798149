import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import useJWT from '../../hooks/useJWTStore';
// wrapper component for fetching the access token and writing it to the store on initialization
const JWTProvider = ({ children, auth0Audience, auth0Scope, }) => {
    const { setJWT, jwt } = useJWT();
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        const setJWTAsync = async () => {
            // TODO ward: getAccessTokenSilently gets called right after logging out, resulting in an error because the (just cleared) 'refresh token' isn't found. Fix this while fixing the authorizaiton error when first logging in
            try {
                if (!isLoading && isAuthenticated && !jwt) {
                    const accessToken = await getAccessTokenSilently({
                        detailedResponse: true,
                        authorizationParams: {
                            audience: auth0Audience,
                            scope: auth0Scope,
                        },
                    });
                    setJWT(accessToken);
                }
            }
            catch (e) {
                console.warn(e);
            }
        };
        setJWTAsync();
    }, [isLoading, isAuthenticated, getAccessTokenSilently, jwt]);
    return children;
};
export default JWTProvider;
