import { StrategiesDataType } from 'pages/strategy/types/strategies';
import {
	useAllLatestRunStatusesQuery,
	UseAllLatestRunStatusesQueryParams,
} from './useAllLatestRunStatusesQuery';

const useStrategiesWithStatus = (
	strategies: StrategiesDataType,
	statusQueryOptions: UseAllLatestRunStatusesQueryParams = undefined,
) => {
	const statusesResponse = useAllLatestRunStatusesQuery(statusQueryOptions);
	return strategies.map((strategy) => {
		const matchedStatus =
			(statusesResponse.data ?? {})[strategy.id] ?? undefined;
		if (matchedStatus) {
			return {
				...strategy,
				status: matchedStatus,
				statusState: 'valid',
			};
		}
		return {
			...strategy,
			status: matchedStatus,
			statusState: statusesResponse.isSuccess ? 'never_ran' : 'loading',
		};
	});
};

export default useStrategiesWithStatus;
