import FullCumulioDashboard from 'components/FullCumulioDashboard/FullCumulioDashboard';
import { GET_CUMULIO_ACTUALS_SALES_SSO } from '../../../api/reports';
import filterMapToSearchParams from '../../../components/Filter/filterUtils';

const ActualsSales = ({ filters }) => {
	return (
		<FullCumulioDashboard
			queryKey={['cumulio-actuals-sales-initial', Object.fromEntries(filters)]}
			ssoQuery={() =>
				GET_CUMULIO_ACTUALS_SALES_SSO(filterMapToSearchParams(filters))
			}
			showLoading
		/>
	);
};

export default ActualsSales;
