import api from 'api/api';
import { Button, useToast } from 'crunch-components';
import { HTTPError } from 'ky';
import { ChangeEvent, MouseEvent, useRef } from 'react';

export type UploadButtonProps = {
	refetchLatestUploads: () => void;
	uploaderId: string;
	className?: string;
};

const allowedTypes = [
	'application/vnd.ms-excel', // .xls
	'application/vnd.oasis.opendocument.spreadsheet', // .ods
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
	'text/csv', // .csv
];

async function upload(uploader_id: string, data: FormData) {
	return api.post(`api/v2/uploaders/${uploader_id}/upload`, {
		body: data,
	});
}

const UploadButton = ({
	refetchLatestUploads,
	uploaderId,
	className,
}: UploadButtonProps) => {
	const hiddenFileInput = useRef<HTMLInputElement>(null);
	const { show: showToast } = useToast.getState();

	const handleButtonClick = (e: MouseEvent<HTMLButtonElement> | undefined) => {
		e?.preventDefault();
		hiddenFileInput?.current?.click();
	};

	const handleChange = async (
		event: ChangeEvent<HTMLInputElement>,
		uploader_id: string,
	) => {
		if (event.target.files === null) {
			return;
		}
		const file = event.target.files[0];

		const data = new FormData();
		data.append('file', file);

		const maxFileSizeMb = window._ENV_.REACT_APP_MAX_FILE_SIZE_MB;
		if (file.size > maxFileSizeMb * 1e6) {
			showToast(`File too big: max ${maxFileSizeMb}MB`, {
				type: 'error',
			});
			return;
		}

		if (!allowedTypes.includes(file.type)) {
			showToast(`File type ${file.type} not supported`, {
				type: 'error',
			});
			return;
		}

		try {
			await upload(uploader_id, data);
		} catch (error: unknown) {
			const message =
				error instanceof HTTPError
					? `File upload failed: ${(await error.response.json()).detail}`
					: 'File upload failed';

			if (error instanceof HTTPError) {
				showToast(message, { type: 'error' });
			}
		}

		refetchLatestUploads();
	};

	return (
		<form className={className}>
			<Button
				size="small"
				variant="primary"
				onClick={(e) => handleButtonClick(e)}
			>
				Upload file
			</Button>
			<input
				type="file"
				hidden
				ref={hiddenFileInput}
				onChange={(e) => handleChange(e, uploaderId)}
				accept={allowedTypes.join(', ')}
			/>
		</form>
	);
};

export default UploadButton;
