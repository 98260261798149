import api from 'api/api';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import useChannelStore from 'hooks/channels/useChannelStore';
import { Prettify } from 'pages/strategy/types/helpers';
import { useMutation, useQueryClient, UseQueryOptions } from 'react-query';
import constructChannelQueryKey from 'utils/channelUtils';
import { paths } from '../../../types/backend-api';

// andrey - strategy/v2/queries.ts
export const LIST_BUSINESS_RULES_QUERY_KEY = ['businessRules'];
export const PATCH_BUSINESS_RULE_MUTATION_KEY = ['patchBusinessRule'];

// andrey - strategy/types/strategies.ts
export type BusinessRulesDataType =
	paths['/v2/business-rules']['get']['responses']['200']['content']['application/json'];

type BusinessRulePatchDataType =
	paths['/v2/business-rules/{business_rule_id}']['patch']['responses']['200']['content']['application/json'];

type PatchParameters = {
	businessRuleId: Prettify<
		paths['/v2/business-rules/{business_rule_id}']['patch']['parameters']['path']['business_rule_id']
	>;
	channel: Prettify<
		paths['/v2/business-rules/{business_rule_id}']['patch']['parameters']['header']['channel']
	>;
	body: Prettify<
		paths['/v2/business-rules/{business_rule_id}']['patch']['requestBody']['content']['application/json']
	>;
};

// andrey - api/strategies.js
export const LIST_BUSINESS_RULES_FN = (): Promise<BusinessRulesDataType> => {
	return api.get('api/v2/business-rules').json();
};

export const PATCH_BUSINESS_RULE = (
	params: PatchParameters,
): Promise<BusinessRulePatchDataType> => {
	return api
		.patch(`api/v2/business-rules/${params.businessRuleId}`, {
			body: JSON.stringify(params.body),
			headers: { channel: params.channel, 'content-type': 'application/json' },
		})
		.json();
};

const useBusinessRulesQuery = (
	useQueryOptions?: UseQueryOptions<
		BusinessRulesDataType,
		Error,
		BusinessRulesDataType
	>,
) => {
	const result = useChannelQuery<
		BusinessRulesDataType,
		Error,
		BusinessRulesDataType
	>(LIST_BUSINESS_RULES_QUERY_KEY, LIST_BUSINESS_RULES_FN, {
		keepPreviousData: true,
		...useQueryOptions,
	});
	return result;
};

export const useBusinessRulePatch = () => {
	const { activeChannel } = useChannelStore();

	const queryClient = useQueryClient();

	const listQueryKey = constructChannelQueryKey(
		activeChannel,
		LIST_BUSINESS_RULES_QUERY_KEY,
	);

	return useMutation({
		mutationKey: PATCH_BUSINESS_RULE_MUTATION_KEY, // don't know if this needs to be more specific or something - TODO: make sure this doesn't cause issues.
		mutationFn: (params: {
			businessRuleId: PatchParameters['businessRuleId'];
			body: PatchParameters['body'];
		}) =>
			PATCH_BUSINESS_RULE({
				businessRuleId: params.businessRuleId,
				body: params.body,
				channel: activeChannel,
			}),

		onMutate: async (params: {
			businessRuleId: PatchParameters['businessRuleId'];
			body: PatchParameters['body'];
		}) => {
			await queryClient.cancelQueries(listQueryKey);
			const previousBusinessRules = queryClient.getQueryData(
				listQueryKey,
			) as BusinessRulesDataType;

			queryClient.setQueryData(
				listQueryKey,
				// @ts-ignore
				(old: BusinessRulesDataType) => {
					// merge the changes into the old one.
					// input (= patchBody) is a Optional<BusinessRulesDataType['items'][number]> type -> single business rule object where every property is optional
					// Ouput must be BusinessRulesDataType -> object with 'items' with the business rules inside

					if (old === undefined) {
						return old;
					}

					const brIndex = old?.items?.findIndex(
						(brHay) => brHay.id === params.businessRuleId,
					);

					if (brIndex === -1) {
						return undefined;
					}

					const br = old.items[brIndex];
					const { phase_assignments: phaseAssignments, ...easyMergeBody } =
						params.body;
					const mergedBr: BusinessRulesDataType['items'][number] = {
						...br,
						...easyMergeBody,
					};

					phaseAssignments?.forEach((patchPartPhase) => {
						const phaseAssignmentToChange = mergedBr.phase_assignments?.find(
							(phaseAssignment) => phaseAssignment.id === patchPartPhase.id,
						);

						patchPartPhase.strategies.forEach((patchPartStrategy) => {
							const strategyToUpdate = phaseAssignmentToChange?.strategies.find(
								(oldStrategy) => oldStrategy.id === patchPartStrategy.id,
							);
							if (!strategyToUpdate) {
								return;
							}
							strategyToUpdate.active = patchPartStrategy.active;
						});
					});

					const mergedBusinessRules = {
						...old,
						items: [...old.items],
					};
					mergedBusinessRules.items[brIndex] = br;
					return JSON.parse(JSON.stringify(mergedBusinessRules));
				},
			);

			return { previousBusinessRules };
		},
		onError: (error, variables, context) => {
			queryClient.setQueryData(listQueryKey, context?.previousBusinessRules);
		},
		onSettled: () => {
			queryClient.invalidateQueries(listQueryKey);
		},
	});
};

export default useBusinessRulesQuery;
