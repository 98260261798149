import * as React from 'react';
import { ModalType } from '../types/strategies';

// business rule
export type RowDataType = {
	id: string;
	name: string;
};

// phase
export type ColumnDataType = {
	id: string;
	name: string;
};

// columnId: checked
export type RowValues = Record<string, boolean>;

// rowId: rowValues
export type GridState = Record<string, RowValues>;

export type CheckboxCellProps = {
	rowId: string;
	columnId: string;
	checked: boolean;
	onChange: (args: {
		rowId: string;
		columnId: string;
		checked: boolean;
	}) => void;
};

export type InitGridArgs = {
	rows: RowDataType[];
	columns: ColumnDataType[];
	scenario: ModalType;
};

export type ActionType =
	| {
			type: 'TOGGLE_CHECKBOX';
			payload: {
				rowId: string;
				columnId: string;
				checked: boolean;
			};
	  }
	| {
			type: 'TOGGLE_ROW';
			payload: { rowId: string };
	  }
	| {
			type: 'TOGGLE_COLUMN';
			payload: { columnId: string };
	  }
	| {
			type: 'RESET';
			payload: { state: GridState };
	  };

export const checkboxReducer = (state: GridState, action: ActionType) => {
	switch (action.type) {
		case 'RESET': {
			const { state: newState } = action.payload;
			return newState;
		}

		case 'TOGGLE_CHECKBOX': {
			const { rowId, columnId, checked } = action.payload;
			const newState = { ...state };

			const row = newState[rowId];
			row[columnId] = checked;

			return newState;
		}

		case 'TOGGLE_ROW': {
			const { rowId } = action.payload;
			const row = state[rowId];
			const items = Array.from(Object.values(row));

			const itemCount = items.length;
			const checkedCount = items.filter((active) => active).length;
			// If all are checked: uncheck all.
			// If some or none are checked: check all.
			const newCheckedState = checkedCount !== itemCount;

			const newRow = { ...state[rowId] };
			// eslint-disable-next-line no-restricted-syntax
			for (const k of Object.keys(newRow)) {
				newRow[k] = newCheckedState;
			}

			return { ...state, [rowId]: newRow };
		}

		case 'TOGGLE_COLUMN': {
			const { columnId } = action.payload;

			const column = Object.entries(state).reduce(
				(acc, [rowId, rowColumns]) => {
					return acc.concat({ rowId, active: rowColumns[columnId] });
				},
				<Array<{ rowId: string; active: boolean }>>[],
			);

			const itemCount = column.length;
			const checkedCount = column.filter((c) => c.active).length;
			// If all are checked: uncheck all.
			// If some or none are checked: check all.
			const newCheckedState = checkedCount !== itemCount;

			const newState = { ...state };
			// eslint-disable-next-line no-restricted-syntax
			for (const { rowId } of Object.values(column)) {
				newState[rowId] = { ...newState[rowId], [columnId]: newCheckedState };
			}

			return newState;
		}

		default:
			return state;
	}
};

export const initGrid = ({ rows, columns, scenario }: InitGridArgs) => {
	const state: GridState = {};

	rows.forEach((row) => {
		const rowData: any = {};
		columns.forEach((column) => {
			if (scenario === 'update') {
				// @ts-ignore
				const asn = row.phase_assignments.find((item) => item.id === column.id);
				rowData[column.id] = asn?.active ?? false;
				return;
			}
			rowData[column.id] = false;
		});
		state[row.id] = rowData;
	});
	return state;
};

export type GridDispatchType = React.Dispatch<ActionType>;
