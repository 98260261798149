import useChannelStore from 'hooks/channels/useChannelStore';
import { Navigate, Outlet } from 'react-router-dom';
import { FEATURES_MAP, isFeatureEnabled } from 'utils/featureUtils';

export default function ConditionalFeatureRoute(
	props:
		| {
				children: JSX.Element;
				feature: keyof typeof FEATURES_MAP;
				renderMethod: 'children';
		  }
		| {
				feature: keyof typeof FEATURES_MAP;
				renderMethod: 'outlet';
		  },
) {
	const { activeChannel } = useChannelStore();

	if (isFeatureEnabled(props.feature, activeChannel) === false) {
		return <Navigate to="/" />;
	}

	if (props.renderMethod === 'outlet') {
		return <Outlet />;
	}

	return props.children;
}
