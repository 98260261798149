import { Outlet } from 'react-router-dom';
import StrategyHeader from './StrategyHeader';

export default function StrategyLayout() {
	return (
		<section>
			<StrategyHeader />
			<Outlet />
		</section>
	);
}
