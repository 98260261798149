
import { Tooltip, cn } from 'crunch-components';
import PropTypes from 'prop-types';

const FilterTag = ({ field, value, onClick, warning, warningText }) => (
	// TODO WARD: make the disabled filters look good
	<Tooltip content={warning ? warningText : undefined}>
		<button
			className={cn(
				'truncate max-w-md p-2 text-xs rounded-lg transition-colors leading-none focus:outline-none focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple focus-visible:bg-ca-silver-a hover:bg-ca-silver-a active:bg-ca-silver-a',
				warning ? 'bg-ca-gray-200 text-ca-gray' : 'bg-ca-silver text-ca-black'
			)}
			type="button"
			onClick={onClick}
		>
			{field} <span className="text-ca-gray">is</span>{' '}
			{Array.isArray(value) ? value?.join(', ') : value}
		</button>
	</Tooltip>
);

FilterTag.propTypes = {
	field: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]).isRequired,
	onClick: PropTypes.func,
	warning: PropTypes.bool,
	warningText: PropTypes.string,
};

FilterTag.defaultProps = {
	onClick: () => { },
	warning: false,
};

export default FilterTag;
