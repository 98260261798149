import { GET_CUMULIO_ACTUALS_INVENTORY_SSO } from '../../../api/reports';
import filterMapToSearchParams from '../../../components/Filter/filterUtils';
import FullCumulioDashboard from '../../../components/FullCumulioDashboard/FullCumulioDashboard';

const ActualsInventory = ({ filters }) => {
	return (
		<FullCumulioDashboard
			queryKey={['cumulio-actuals-inventory', Object.fromEntries(filters)]}
			ssoQuery={() =>
				GET_CUMULIO_ACTUALS_INVENTORY_SSO(filterMapToSearchParams(filters))
			}
			showLoading
		/>
	);
};

export default ActualsInventory;
