import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Logo = ({ className }) => (_jsx("svg", { className: className, viewBox: "0 0 48 22", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "m34.1694915 11.3203883v10.6733296h-6.5084746v-4.9746574zm2.8018983-11.31410618c2.797017-.00303524 5.5395255 1.01534838 7.6108475 3.07509995 2.0902303 2.07970814 3.2885596 4.8482688 3.3950792 7.82505783l.0072259.3197166.0154576 10.7675614h-6.5084746l-.0154576-10.7377213c-.0113898-1.1307824-.4791864-2.23015422-1.3171525-3.06410625-.9201356-.91797544-2.1852204-1.40798116-3.3844068-1.31767562-2.5309921.16171331-4.9986124 2.00985913-7.7223232 4.31225977l-.5649588.4843735-.5678338.499127c-.0948794.0843109-.1898792.1691569-.2849998.2544979l-.828858.7771432-1.1146256 1.0313458-.5607023.5107855-.5633136.5055958c-.0941179.0837531-.1883555.1672295-.2827184.2503992l-.5677247.4950957c-.094886.081822-.1899083.1632768-.2850723.2443344l-.5727289.4813365c-3.4471072 2.8565173-7.0921611 5.010782-11.1913949 5.2732089-.2139661.0141348-.4271186 0-.638644 0-2.79701698.0030352-5.53952545-1.0153484-7.61084749-3.0751-2.09023027-2.0797081-3.28855962-4.8482688-3.39507922-7.8250578l-.00722586-.3197166-.01545763-10.76756138h6.50847458l.01545762 10.73772128c.01138983 1.1307824.47918644 2.2301542 1.31715255 3.0641063.92013559.9179754 2.18522035 1.4079811 3.38440675 1.3176756 2.7053296-.1728523 5.5627209-2.1312302 8.4888394-4.6732939l.5677853-.49907008c.0948713-.0843015.1898629-.16913798.2849753-.25446959l1.386973-1.29355683.8382063-.77066988.5618618-.50848656.5646149-.50256256.5676312-.49519061.5709105-.48637067.574453-.47610276c3.3618202-2.74450259 6.7156374-4.90459251 10.7010036-5.15972974.2139661-.01413478.4271186 0 .638644 0zm-16.6324067 0v4.97465734l-6.5084746 5.69867224v-10.67332958z", fill: "#141414", fillRule: "evenodd" }) }));
Logo.propTypes = {
    className: PropTypes.string,
};
Logo.defaultProps = {
    className: '',
};
export default Logo;
