import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const CurrentStep = ({ className }) => {
    return (_jsxs("svg", { className: className, xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", children: [_jsx("rect", { width: "16", height: "16", fill: "#C99EFF", rx: "8" }), _jsx("rect", { width: "8", height: "8", x: "4", y: "4", fill: "#6111C7", rx: "4" })] }));
};
CurrentStep.propTypes = {
    className: PropTypes.string,
};
CurrentStep.defaultProps = {
    className: '',
};
export default CurrentStep;
