import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Calendar = ({ className }) => (_jsx("svg", { className: className, viewBox: "0 0 20 20", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "m2 18h16v-12h-16zm16-16v-2h-2v2h-5v-2h-2v2h-5v-2h-2v2h-2v18h20v-18z", fill: "currentColor", fillRule: "evenodd" }) }));
Calendar.propTypes = {
    className: PropTypes.string,
};
Calendar.defaultProps = {
    className: '',
};
export default Calendar;
