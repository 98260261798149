import ChannelSelector from 'components/ChannelSelector/ChannelSelector';
import { Title } from 'crunch-components';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

function GlobalHeader() {
	return (
		<div className="relative flex justify-between">
			<Helmet title="Crunch Platform | Global" />
			<Title>Global</Title>
			<ChannelSelector />
		</div>
	);
}

export default function GlobalLayout() {
	return (
		<section>
			<GlobalHeader />
			<Outlet />
		</section>
	);
}
