import { Button, PlusIcon, Text } from 'crunch-components';
import TableBusinessRules from '../Components/OverviewTable';
import { useCreateModalBusinessRule } from './components/BusinessRuleCreateEditModalBase';

const BusinessRulesOverview = () => {
	const { open } = useCreateModalBusinessRule();
	const jsx = (
		<>
			<div className="flex gap-4 items-center justify-between">
				<Text type="secondary" className="py-10 flex-shrink">
					Business rules allow you to make sure the recommended markdowns are in
					line with the specific rules and constraints of your organization.
				</Text>
				<Button
					size="page-cta"
					onClick={open}
					className="w-[168px] max-w-[168px] flex h-[30px] items-center justify-center gap-2 flex-grow font-normal"
				>
					<span className="whitespace-nowrap">Add business rule</span>
					<PlusIcon className="h-2.5 w-auto" />
				</Button>
			</div>
			<TableBusinessRules />
		</>
	);

	return jsx;
};

export default BusinessRulesOverview;
