import { Outlet } from 'react-router-dom';
import ActualsHeader, { ActualsHeaderProps } from './ActualsHeader';

export default function ActualsLayout({
	filters,
	setFilters,
}: ActualsHeaderProps) {
	return (
		<section>
			<ActualsHeader filters={filters} setFilters={setFilters} />
			<Outlet />
		</section>
	);
}
