import { Route } from 'react-router-dom';
import ConditionalFeatureRoute from 'routing/components/ConditionalFeatureRoute';
import GlobalLayout from './GlobalLayout';
import GlobalDashboard from './views/GlobalDashboard';

const globalRouting = (
	<Route
		path="/global"
		element={
			<ConditionalFeatureRoute feature="global" renderMethod="children">
				<GlobalLayout />
			</ConditionalFeatureRoute>
		}
	>
		<Route index element={<GlobalDashboard />} />
	</Route>
);

export default globalRouting;
