import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Table = ({ className }) => (_jsxs("svg", { className: className, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "1", y: "1", width: "18", height: "18", rx: "1", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round" }), _jsx("path", { d: "M7 2L7 18", stroke: "currentColor", strokeWidth: "2" }), _jsx("path", { d: "M13 2L13 18", stroke: "currentColor", strokeWidth: "2" }), _jsx("path", { d: "M2 7L18 7", stroke: "currentColor", strokeWidth: "2" }), _jsx("path", { d: "M2 13L18 13", stroke: "currentColor", strokeWidth: "2" })] }));
Table.propTypes = {
    className: PropTypes.string,
};
Table.defaultProps = {
    className: '',
};
export default Table;
