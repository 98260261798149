import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const TrianglesDown = ({ className, style }) => (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", className: className, style: style, width: "20", height: "15", fill: "none", viewBox: "0 0 20 15", children: [_jsx("path", { fill: "currentColor", stroke: "#fff", strokeWidth: "2", d: "M6.268 11.5c.77 1.333 2.694 1.333 3.464 0L12.33 7c.77-1.333-.192-3-1.732-3H5.402C3.862 4 2.9 5.667 3.67 7l2.598 4.5z" }), _jsx("path", { fill: "currentColor", stroke: "#fff", strokeWidth: "2", d: "M10.268 11.5c.77 1.333 2.694 1.333 3.464 0L16.33 7c.77-1.333-.192-3-1.732-3H9.402C7.862 4 6.9 5.667 7.67 7l2.598 4.5z" })] }));
TrianglesDown.propTypes = {
    className: PropTypes.string,
    style: PropTypes.any,
};
TrianglesDown.defaultProps = {
    className: '',
};
export default TrianglesDown;
