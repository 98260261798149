import { jsx as _jsx } from "react/jsx-runtime";
import cn from '../../utils/cn';
import PropTypes from 'prop-types';
const Title = ({ type, className, children }) => {
    if (type === 'section') {
        return (_jsx("h2", { className: cn('text-ca-section text-ca-black font-bold', className), children: children }));
    }
    if (type === 'subsection') {
        return (_jsx("h3", { className: cn('text-ca-subsection text-ca-gray font-medium', className), children: children }));
    }
    return (_jsx("h1", { className: cn('text-ca-screen text-ca-purple font-bold', className), children: children }));
};
Title.propTypes = {
    type: PropTypes.oneOf(['screen', 'section', 'subsection']),
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
Title.defaultProps = {
    className: '',
    type: 'screen',
};
export default Title;
