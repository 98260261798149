/* eslint-disable no-unused-vars */
// todo ward: remove eslint-disable and check the eslint warning
import { entriesFromObject } from 'crunch-utils';

export type WarningCreator<T> = Record<
	string,
	{
		trigger: (input: T) => boolean;
		warningMessage: string;
	}
>;

function getWarnings<T extends WarningCreator<any>>(
	warningDefinitions: T,
	input: ExtractGenericType<T>,
): {
	count: number;
	warnings: Partial<Record<keyof T, T[keyof T]['warningMessage']>>;
} {
	const warnings = entriesFromObject(warningDefinitions).reduce<
		ReturnType<typeof getWarnings<T>>['warnings']
	>((acc, [warningKey, warningValue]) => {
		if (warningValue.trigger(input)) {
			acc[warningKey] = warningValue.warningMessage;
		}
		return acc;
	}, {});

	return { warnings, count: Object.keys(warnings).length };
}

export function constructGetWarningsFunction<T extends WarningCreator<any>>(
	warningDefinitions: T,
): (input: ExtractGenericType<T>) => ReturnType<typeof getWarnings<T>> {
	return (input: ExtractGenericType<T>) =>
		getWarnings(warningDefinitions, input);
}

type ExtractGenericType<U> = U extends WarningCreator<infer T> ? T : never;
