import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CrossIcon from '../Icons/Cross';
import cn from '../../utils/cn';
export var TagColor;
(function (TagColor) {
    TagColor[TagColor["Blue"] = 0] = "Blue";
    TagColor[TagColor["Green"] = 1] = "Green";
    TagColor[TagColor["Grey"] = 2] = "Grey";
    TagColor[TagColor["Black"] = 3] = "Black";
    TagColor[TagColor["Orange"] = 4] = "Orange";
    TagColor[TagColor["Purple"] = 5] = "Purple";
    TagColor[TagColor["Red"] = 6] = "Red";
    TagColor[TagColor["White"] = 7] = "White";
})(TagColor || (TagColor = {}));
const COLOR_CLASSES = {
    [TagColor.Blue]: 'text-ca-blue border-ca-blue',
    [TagColor.Grey]: 'text-ca-grey border-ca-grey',
    [TagColor.Black]: 'text-ca-black border-ca-black',
    [TagColor.Green]: 'text-ca-green border-ca-green',
    [TagColor.Orange]: 'text-ca-orange border-ca-orange',
    [TagColor.Purple]: 'text-ca-purple border-ca-purple',
    [TagColor.Red]: 'text-ca-red border-ca-red',
    [TagColor.White]: 'text-ca-black border-ca-silver',
};
const Tag = ({ label, color = TagColor.White, removable, onClick, }) => {
    if (!label)
        return null;
    return (_jsxs("button", { tabIndex: onClick ? 0 : -1, className: cn('m-1 inline-flex justify-center items-center px-2.5 bg-white font-bold leading-none text-xxs border rounded-full transition-colors focus:outline-none', !onClick && 'cursor-text', !!onClick &&
            'cursor-pointer hover:border-ca-purple hover:bg-opacity-10 hover:bg-ca-purple focus:border-ca-purple focus:bg-opacity-10 focus:bg-ca-purple group', COLOR_CLASSES[color]), style: {
            paddingTop: '5px',
            paddingBottom: '5px',
        }, onClick: onClick, children: [_jsx("span", { children: label }), removable && (_jsx("span", { children: _jsx(CrossIcon, { className: "ml-2.5 h-2 w-2 text-ca-gray" }) }))] }));
};
export default Tag;
