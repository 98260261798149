import { useToast } from 'crunch-components';
import { HTTPError } from 'ky';
import React from 'react';
import {
	MutationCache,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: (failureCount, err) => {
				if (err instanceof HTTPError) {
					if (failureCount >= 3 && err?.response?.status !== 401) {
						return false;
					}
					return err?.response?.status !== 404;
				}
				throw new Error(
					'[StratosQueryClientProvider::defaultOptions::queries::retry] Either developer did not use ky for request or the error was not of type HTTPError. You should use ky for requests or figure out what went wrong.',
				);
			},
		},
	},
	queryCache: new QueryCache({
		onError: async (error, _query) => {
			if (!(error instanceof HTTPError)) {
				throw new Error(
					'[StratosQueryClientProvider::queryCache::onError] Error not instance of HTTPError. Either developer did not use ky package for request or the error was not of type HTTPError. You should use ky for requests or figure out what went wrong.',
				);
			}

			// no 401 toast for channels call
			if (
				error.response?.status === 401 &&
				error.response?.url.includes('/api/v2/channels')
			) {
				return;
			}

			// no toasts for algorithm run status
			if (error.response?.url.includes('/run/status')) {
				return;
			}

			if (error.response) {
				const { detail } = await error.response.json();

				const { show, toasts } = useToast.getState();

				if (window.location.pathname === '/login') {
					// don't show error toasts on login screen
					return;
				}

				// check if there is already a 401/403 toast being shown
				// to prevent the user from being flooded by "Error 401/403: Unauthorized" messages
				if (error.response?.status === 401 || error.response?.status === 403) {
					const unauthorizedToast = toasts.find(
						(t) =>
							t.message.includes('Error 401') ||
							(t.message.includes('Error 403') && t.visible),
					);

					if (unauthorizedToast) return;
				}

				show(
					`Error ${error.response?.status}: ${
						typeof detail === 'string'
							? detail
							: error.response?.statusText || 'Unknown error'
					}`,
					{
						type: 'error',
					},
				);
			}
		},
	}),
	mutationCache: new MutationCache({
		onError: async (error) => {
			if (!(error instanceof HTTPError)) {
				throw new Error(
					'[QueryClientProvider::mutationCache::onError] Error not instance of HTTPError. Either developer did not use ky package for request or the error was not of type HTTPError. You should use ky for requests or figure out what went wrong.',
				);
			}
			const { detail } = await error.response.json();

			useToast
				.getState()
				.show(
					`Error ${error.response?.status}: ${
						typeof detail === 'string'
							? detail
							: error.response?.statusText || 'Unknown error'
					}`,
					{
						type: 'error',
					},
				);
		},
	}),
});

const localStoragePersistor = createWebStoragePersistor({
	storage: window.localStorage,
});

persistQueryClient({
	queryClient,
	persistor: localStoragePersistor,
});

const StratosQueryClientProvider: React.FC<{ children: React.ReactNode }> = (
	props,
) => (
	<QueryClientProvider client={queryClient}>
		{props.children}
	</QueryClientProvider>
);

export default StratosQueryClientProvider;
