import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
// ^ Because of package problems
import Tippy from '@tippyjs/react/headless';
const Tooltip = ({ children, content, isVisible, placement = 'top', }) => {
    if (!content)
        return _jsx(_Fragment, { children: children });
    return (_jsx(Tippy, { placement: placement, visible: isVisible, render: (attrs) => (_jsx("div", { className: "bg-white text-ca-black text-xs p-2.5 rounded-lg shadow-ca max-w-sm", tabIndex: -1, ...attrs, children: content })), children: children }));
};
export default Tooltip;
