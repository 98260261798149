import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
// Redirects to home page based on authentication status
export default function SmartHomeRedirect(props) {
    const location = useLocation();
    const { isAuthenticated, isLoading } = useAuth0();
    if (isLoading) {
        return _jsx(Outlet, {});
    }
    const homePath = isAuthenticated
        ? props.homePaths['private']
        : props.homePaths['public'];
    return _jsx(Navigate, { to: homePath, state: { from: location.pathname }, replace: true });
}
