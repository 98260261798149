import { CircularProgress } from 'crunch-components';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import useStrategyStore from 'hooks/useStrategyStore';
import { StrategiesDataType } from 'pages/strategy/types/strategies';
import { STRATEGY_QUERY_KEY } from 'pages/strategy/v2/queries';
import { createContext, PropsWithChildren } from 'react';
import { LIST_STRATEGIES } from '../api/strategies-v2';

const StrategyContext = createContext({});

const StrategyProvider = ({ children }: PropsWithChildren) => {
	const { setStrategies, activeStrategy, setActiveStrategy } =
		useStrategyStore();

	const query = useChannelQuery<
		StrategiesDataType,
		unknown,
		StrategiesDataType
	>(STRATEGY_QUERY_KEY, LIST_STRATEGIES, {
		onSuccess: (strategies: StrategiesDataType) => {
			// If there are no strategies, do nothing
			if (strategies.length === 0) {
				return;
			}

			setStrategies(strategies);

			// If there is no active strategy, set the first strategy as active
			if (activeStrategy === null) {
				setActiveStrategy(strategies[0].id);
			}

			// If the active strategy is not in the list of strategies, set the first strategy as active
			const active = strategies.find(({ id }) => id === activeStrategy);
			if (active === undefined) {
				setActiveStrategy(strategies[0].id);
			}
		},
	});

	if (query.isLoading) {
		return (
			<div className="min-h-screen flex justify-center items-center">
				<CircularProgress />
			</div>
		);
	}

	return <>{children}</>;
};
export { StrategyProvider, StrategyContext };
