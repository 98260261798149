import ChannelSelector from 'components/ChannelSelector/ChannelSelector';
import { FilterMap } from 'components/Filter/FullFilterMenu.types';
import HeaderNav from 'components/HeaderNav/HeaderNav';
import { Greetings, Title } from 'crunch-components';
import { Dispatch, SetStateAction } from 'react';
import { Helmet } from 'react-helmet-async';
import ActualsFilter from './components/ActualsFilter';

const NAV_ITEMS = [
	{
		id: 'inventory',
		label: 'Inventory',
		to: '/actuals/inventory',
	},
	{
		id: 'sales',
		label: 'Sales',
		to: '/actuals/sales',
	},
	{
		id: 'yoycomparison',
		label: 'YoY Comparison',
		to: '/actuals/yoycomparison',
	},
	// Product Actuals commented out to disable it. Can be used later on
	// {
	//	id: 'product',
	//	label: 'Product Actuals',
	//	to: '/actuals/product',
	// },
];

export type ActualsHeaderProps = {
	filters: FilterMap;
	setFilters: Dispatch<SetStateAction<FilterMap>>;
};

export default function ActualsHeader({
	filters,
	setFilters,
}: ActualsHeaderProps) {
	return (
		<header>
			<Helmet title="Crunch Platform | Actuals" />
			<div className="flex justify-between">
				<div className="flex flex-col gap-2">
					<Title>Actuals</Title>
					<Greetings />
				</div>
				<div className="flex flex-col gap-2">
					<ChannelSelector />
				</div>
			</div>
			<div className="mt-6">
				<HeaderNav items={NAV_ITEMS} />
			</div>
			<div className="mt-6">
				<ActualsFilter filters={filters} setFilters={setFilters} />
			</div>
		</header>
	);
}
