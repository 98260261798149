import create from 'zustand';
import { persist } from 'zustand/middleware';

const store = (set) => ({
	disabledHeadings: [],
	defaultDisabledHeadings: [],
	toggleHeading: (headingId) =>
		set((state) => {
			if (state.disabledHeadings.includes(headingId)) {
				return {
					disabledHeadings: state.disabledHeadings.filter(
						(h) => h !== headingId,
					),
				};
			}

			return { disabledHeadings: [...state.disabledHeadings, headingId] };
		}),
	addToDefaultDisabledHeading: (headingId) =>
		set((state) => ({
			defaultDisabledHeadings: [...state.defaultDisabledHeadings, headingId],
		})),
	resetHeadings: () =>
		set((state) => ({
			disabledHeadings: state.defaultDisabledHeadings,
		})),
});

const useRecommendationsHeadings = create(
	persist(store, {
		name: 'ca-recommendations-headings-store',
		whitelist: ['disabledHeadings'],
	}),
);

const useProductCategoriesHeadings = create(
	persist(store, {
		name: 'ca-product-categories-headings-store',
		whitelist: ['disabledHeadings'],
	}),
);

export { useRecommendationsHeadings, useProductCategoriesHeadings };
