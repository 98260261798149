import { GET_CUMULIO_ACTUALS_YOYCOMPARISON_SSO } from 'api/reports';
import filterMapToSearchParams from 'components/Filter/filterUtils';
import FullCumulioDashboard from 'components/FullCumulioDashboard/FullCumulioDashboard';

const ActualsYoYComparison = ({ filters }) => {
	return (
		<FullCumulioDashboard
			queryKey={['cumulo-actuals-yoycomparison', Object.fromEntries(filters)]}
			ssoQuery={() =>
				GET_CUMULIO_ACTUALS_YOYCOMPARISON_SSO(filterMapToSearchParams(filters))
			}
			showLoading
		/>
	);
};

export default ActualsYoYComparison;
