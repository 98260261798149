import { Dropdown } from 'crunch-components';
import { ComponentProps } from 'react';
import { EUnit, MarkdownBucketDataType } from '../types/MarkdownTransferTypes';

type BucketItem = MarkdownBucketDataType & { disabled: boolean };
const primaryKey: keyof MarkdownBucketDataType = 'markdown_int' as const;
type Props = {
	onChange: ComponentProps<typeof Dropdown>['onChange'];
	bucketItems: BucketItem[];
	className?: string;
	unit: EUnit | null;
	value: MarkdownBucketDataType[typeof primaryKey] | null;
};

const MarkdownTransferBucketsDropdown = (props: Props) => {
	const countKey: Record<
		EUnit,
		keyof Pick<MarkdownBucketDataType, 'stock_sum' | 'pid_count'>
	> = { products: 'pid_count', stock: 'stock_sum' } as const;

	const totalInBuckets = props.bucketItems.reduce<{
		totalStock: number;
		totalProducts: number;
	}>(
		(acc, bucket) => {
			acc.totalProducts += bucket.pid_count;
			acc.totalStock += bucket.stock_sum;
			return acc;
		},
		{ totalStock: 0, totalProducts: 0 },
	);

	const getLabel = (bucket: BucketItem) => {
		if (props.unit === null) {
			return `${bucket.markdown_int}%`;
		}

		const totalUnitKey: Record<EUnit, keyof typeof totalInBuckets> = {
			products: 'totalProducts',
			stock: 'totalStock',
		};

		const markdown = `${bucket.markdown_int}%`;
		const amount = bucket[countKey[props.unit]];

		const amountPercent =
			totalInBuckets[totalUnitKey[props.unit]] === 0
				? 0
				: Math.round(
						(bucket[countKey[props.unit]] /
							totalInBuckets[totalUnitKey[props.unit]]) *
							100,
					);

		return `${markdown} (${amount} ${props.unit} | ${amountPercent}% of ${props.unit})`;
	};

	const options: ComponentProps<typeof Dropdown>['options'] = props.bucketItems
		.map((bucket) => {
			return {
				value: bucket[primaryKey],
				label: getLabel(bucket),
				disabled: bucket.disabled,
			};
		})
		.sort((a, b) => Number(a.value) - Number(b.value));

	return (
		<Dropdown
			options={options}
			className={props.className ?? 'w-full'}
			value={props.value}
			onChange={props.onChange}
		/>
	);
};

export default MarkdownTransferBucketsDropdown;
