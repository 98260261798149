import { COPY_BUSINESS_RULE } from 'api/business-rules';
import useChannelStore from 'hooks/channels/useChannelStore';
import { useMutation, UseMutationOptions } from 'react-query';
import constructChannelQueryKey from 'utils/channelUtils';

const COPY_BUSINESS_RULE_MUTATION_KEY = ['businessRuleCopy'];

export const useBusinessRuleCopy = (
	id: string,
	onSuccess: UseMutationOptions['onSuccess'],
) => {
	const { activeChannel } = useChannelStore();
	return useMutation({
		mutationKey: constructChannelQueryKey(activeChannel, [
			...COPY_BUSINESS_RULE_MUTATION_KEY,
			id,
		]),
		mutationFn: async () => {
			return COPY_BUSINESS_RULE(id);
		},
		onSuccess,
	});
};
