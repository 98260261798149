/* eslint-disable */
import { useQuery, UseQueryOptions } from 'react-query';
import { GET_ALL_RUN_STATUSES } from '../../api/strategies-v2';
import constructChannelQueryKey from 'utils/channelUtils';
import useChannelStore from 'hooks/channels/useChannelStore';

const ALL_LATEST_RUN_STATUSES_QUERY_KEY = [
	'algorithm_status',
	'all_strategies&most_recent',
];
export const useAllLatestRunStatusesQueryKey = () => {
	const { activeChannel } = useChannelStore(); //todo this should show an error........

	return constructChannelQueryKey(
		activeChannel,
		ALL_LATEST_RUN_STATUSES_QUERY_KEY,
	);
};

export type UseAllLatestRunStatusesQueryParams =
	| Pick<
			// :bulb: `Pick` because some properties would change the return type of the query to `unknown`
			UseQueryOptions,
			| 'cacheTime'
			| 'enabled'
			| 'staleTime'
			| 'refetchIntervalInBackground'
	  > & 
	  // :bulb: `refetchInterval` not picked because we don't want the function version of UseQueryOptions['refetchInterval'] because it is a generic function that requires template type
	  	{
		refetchInterval: number | false
		} 
	| undefined;

export const useAllLatestRunStatusesQuery = (
	options: UseAllLatestRunStatusesQueryParams = {
		refetchInterval: 30_000,
		refetchIntervalInBackground: true,
	},
) => {
	const queryKeyWitchChannel = useAllLatestRunStatusesQueryKey();


	return useQuery({
		queryKey: queryKeyWitchChannel,
		queryFn: GET_ALL_RUN_STATUSES,
		...options,
	});
};
