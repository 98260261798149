import { PageNotFound } from 'crunch-components';
import { useNavigate } from 'react-router-dom';

export default function Stratos404() {
	const navigate = useNavigate();
	return (
		<PageNotFound
			onGoBackClick={() => {
				navigate(-1);
			}}
		/>
	);
}
