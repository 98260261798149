import { clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
import twConfig from '../../tailwind.config';
const customTwMerge = extendTailwindMerge({
    extend: {
        classGroups: {
            'font-size': Object.keys(twConfig.theme?.extend?.fontSize).map(key => `text-${key}`),
            'text-color': Object.keys(twConfig.theme?.extend?.colors.ca).map(key => `text-ca-${key}`),
        }
    }
});
export default function cn(...inputs) {
    return customTwMerge(clsx(inputs));
}
