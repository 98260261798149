import { QueryKey } from 'react-query';

const constructChannelQueryKey = (
	activeChannel: string,
	queryKey: QueryKey,
): QueryKey => {
	let key = queryKey;
	if (!Array.isArray(queryKey)) {
		key = [queryKey];
	}

	// :bulb: query key ordering is important for statements like removeQueries/resetQueries
	return [activeChannel, ...key];
};

export default constructChannelQueryKey;
