import { jsx as _jsx } from "react/jsx-runtime";
import cn from '../../utils/cn';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
const CircularProgress = ({ size, onAnimationEnded, className }) => {
    const [isMounted, setIsMounted] = useState(true);
    const [loaderRef, setLoaderRef] = useState();
    useEffect(() => {
        return () => setIsMounted(false);
    }, []);
    useEffect(() => {
        if (loaderRef) {
            const onAnimationIteration = () => {
                if (isMounted) {
                    if (onAnimationEnded) {
                        onAnimationEnded();
                    }
                }
            };
            loaderRef.addEventListener('animationiteration', onAnimationIteration);
            return () => {
                loaderRef.removeEventListener('animationiteration', onAnimationIteration);
            };
        }
        return () => { };
    }, [loaderRef]);
    return (_jsx("svg", { className: cn('w-16 text-ca-purple transform rotate-180', size === 'small' && 'scale-50', size === 'text' && 'w-4', className), viewBox: "0 0 100 100", children: _jsx("path", { className: "stroke-current animate-circular-progress", fill: "none", d: "M 50 96 a 46 46 0 0 1 0 -92 46 46 0 0 1 0 92", style: {
                strokeDasharray: '288.5',
                strokeWidth: 7,
            }, ref: setLoaderRef }) }));
};
CircularProgress.propTypes = {
    size: PropTypes.oneOf(['regular', 'small', 'text']),
    className: PropTypes.any,
    onAnimationEnded: PropTypes.func,
};
CircularProgress.defaultProps = {
    size: 'regular',
    className: '',
    onAnimationEnded: null,
};
export default CircularProgress;
