import ChannelSelector from 'components/ChannelSelector/ChannelSelector';
import { Greetings, Title } from 'crunch-components';

const UploadsHeader = () => (
	<div className="flex justify-between">
		<div className="flex flex-col gap-2">
			<Title>Uploads</Title>
			<Greetings />
		</div>
		<div className="flex flex-col gap-2">
			<ChannelSelector />
		</div>
	</div>
);

export default UploadsHeader;
