import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const Forward = ({ className }) => {
    return (_jsx("svg", { className: className, xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", children: _jsx("path", { fill: "currentColor", d: "M8 0a8 8 0 108 8 8.008 8.008 0 00-8-8zm0 14.77A6.77 6.77 0 1114.77 8 6.776 6.776 0 018 14.77zm3.512-7.205a.616.616 0 010 .87l-2.461 2.462a.616.616 0 11-.871-.87l1.412-1.412H4.923a.615.615 0 110-1.23h4.669L8.18 5.974a.616.616 0 01.87-.87l2.462 2.46z" }) }));
};
Forward.propTypes = {
    className: PropTypes.string,
};
Forward.defaultProps = {
    className: '',
};
export default Forward;
