import { cn, Text, TextSize } from 'crunch-components';
import { FunctionComponent } from 'react';
import {
	EElementState,
	EUnit,
	PhraseObject,
} from '../types/MarkdownTransferTypes';

interface PhraseProps {
	state: EElementState;
	unit: EUnit | null;
	fromObject: PhraseObject;
	toObject: PhraseObject;
	amountObject: PhraseObject;
}

interface DynamicTextProps {
	highlightObject: PhraseObject;
	size: TextSize;
}

const DynamicText: FunctionComponent<DynamicTextProps> = (props) => {
	const isMissing = typeof props.highlightObject?.text !== 'string';
	const replacement = '...';
	return (
		<span className="grid grid-rows-1 grid-cols-1">
			<Text
				className={cn(
					isMissing && 'text-gray-400',
					!isMissing && !props.highlightObject.highlighted && 'text-gray-800',
					props.highlightObject.highlighted
						? 'text-ca-purlple bg-ca-purple text-white shadow-ca-glow'
						: 'bg-gray-200',
					'rounded px-2 z-10 col-start-1 row-start-1',
				)}
				size={props.size}
			>
				{typeof props.highlightObject?.text !== 'string'
					? replacement
					: props.highlightObject.text}
			</Text>
			{props.highlightObject.highlighted && (
				<div className="rounded bg-ca-purple col-start-1 row-start-1 animate-ping opacity-40" />
			)}
		</span>
	);
};

const MarkdownTransferPhrase: FunctionComponent<PhraseProps> = (props) => {
	const defaultHighlightObject: PhraseObject = {
		replacement: '...',
		text: null,
		highlighted: false,
	};

	const amountObject: PhraseObject =
		props.amountObject ?? defaultHighlightObject;
	const toObject: PhraseObject = props.toObject ?? defaultHighlightObject;
	const fromObject: PhraseObject = props.fromObject ?? defaultHighlightObject;

	const textSize = 'text-lg';
	const wrapInText = (contents: string) => (
		<Text size="text-lg" type="secondary">
			{contents}
		</Text>
	);

	return (
		<div className="flex justify-center">
			<div
				className={cn(
					props.state === 'focused'
						? 'border-ca-purple shadow-ca-glow'
						: 'border-ca-gray shadow-ca-glow-off',
					'border rounded-lg w-min px-4 py-2 transition-opacity transition-shadow duration-500',
				)}
			>
				<div className="flex w-max whitespace-pre">
					{wrapInText(`'I will transfer `)}
					<DynamicText size={textSize} highlightObject={amountObject} />
					{wrapInText(` ${props.unit ? props.unit : '?'} from `)}
					<DynamicText size={textSize} highlightObject={fromObject} />
					{wrapInText(' markdown to ')}
					<DynamicText size={textSize} highlightObject={toObject} />
					{wrapInText(` markdown'`)}
				</div>
			</div>
		</div>
	);
};

export default MarkdownTransferPhrase;
