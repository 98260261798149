import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
const DragDrop = ({ className }) => (_jsxs("svg", { className: className, viewBox: "0 0 80 120", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("circle", { cx: "12", cy: "12", r: "12", fill: "currentColor" }), _jsx("circle", { cx: "60", cy: "12", r: "12", fill: "currentColor" }), _jsx("circle", { cx: "12", cy: "60", r: "12", fill: "currentColor" }), _jsx("circle", { cx: "60", cy: "60", r: "12", fill: "currentColor" }), _jsx("circle", { cx: "12", cy: "108", r: "12", fill: "currentColor" }), _jsx("circle", { cx: "60", cy: "108", r: "12", fill: "currentColor" })] }));
DragDrop.propTypes = {
    className: PropTypes.string,
};
DragDrop.defaultProps = {
    className: '',
};
export default DragDrop;
